<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <header class="header-detail">
        <Main-nav :type="type"></Main-nav>
    </header>
    <main class="main">
        <section class="section section--small-mb">
            <div class="section__inner">
                <div class="user-page__header">
                    <h1 :class="`user-page__heading-1 user-page__heading-1--${type}`">{{ $t('LABELS.account-settings') }}</h1>
                    <router-link
                            to="/uzivatel/"
                            class="user-page__heading-btn btn btn--standalone"
                            :class="{
                                'btn--blue': type === 'cruiser',
                                'btn--orange': type === 'yacht'
                            }"
                        >{{ $t('LABELS.my-trips') }}</router-link>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="section__inner">
                <Notification :text="error.text[LANGUAGE]" v-show="error.show" :initConfig="{small: true}" @closed="closeNotification" :type="error.type"></Notification>
                <div class="person-form">
                    <h3 :class="`person-form__heading person-form__heading--${type}`">{{ $t('LABELS.personal-details') }}</h3>
                    <div class="person-form__inputs">
                        <div class="person-form__input-wrapper">
                            <label for="first-name-2" class="person-form__label">{{ $t('LABELS.first-name') }}</label>
                            <input type="text" id="first-name-2" name="first-name" class="person-form__input"
                                v-model="userData.firstName">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="last-name-2" class="person-form__label">{{ $t('LABELS.last-name') }}</label>
                            <input type="text" id="last-name-2" name="surname" class="person-form__input"
                                v-model="userData.lastName">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="gender-2" class="person-form__label">{{ $t('LABELS.sex') }}</label>
                            <v-select class="person-form__select" :options="genderOptions"
                                :reduce="gender => gender.code" label="text" v-model="userData.sex"></v-select>
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="nationality-2" class="person-form__label">{{ $t('LABELS.nationality') }}</label>
                            <v-select class="person-form__select" :options="nationalityOptions"
                                :reduce="nationality => nationality.code" label="text" v-model="userData.nationality">
                            </v-select>
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="date-of-birth-2" class="person-form__label">{{ $t('LABELS.date-of-birth') }}</label>
                            <input type="date" id="date-of-birth-2" class="person-form__input"
                                v-model="userData.birthDate">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="email-1" class="person-form__label">{{ $t('LABELS.email-address') }}</label>
                            <input type="email" id="email-1" name="email" class="person-form__input"
                                v-model="userData.email" readonly>
                        </div>
                        <!-- <div class="person-form__input-wrapper">
                            <label for="street-1" class="person-form__label">{{ $t('LABELS.street-and-number) }}</label>
                            <input type="text" id="street-1" name="street" class="person-form__input"
                                v-model="userData.address">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="city-1" class="person-form__label">{{ $t('LABELS.city) }}</label>
                            <input type="text" id="city-1" name="city" class="person-form__input"
                                v-model="userData.city">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="zip-1" class="person-form__label">{{ $t('LABELS.zip) }}</label>
                            <input type="text" id="zip-1" name="zip" class="person-form__input" v-model="userData.zip">
                        </div> -->
                        <div class="person-form__phone-part">
                            <div class="person-form__input-wrapper">
                                <label for="phone-prefix-1" class="person-form__label">{{ $t('LABELS.phone-prefix') }}</label>
                                <input type="tel" id="phone-prefix-1"
                                    class="person-form__input person-form__input--small" v-model="userData.prefix">
                            </div>
                            <div class="person-form__input-wrapper">
                                <label for="phone-1" class="person-form__label">{{ $t('LABELS.phone-number') }}</label>
                                <input type="tel" id="phone-1" class="person-form__input" v-model="userData.phone">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="person-form">
                    <h3 :class="`person-form__heading person-form__heading--${type}`">{{ $t('LABELS.password') }}</h3>
                    <div class="person-form__inputs">
                        <div class="person-form__input-wrapper">
                            <label for="new-pass" class="person-form__label">{{ $t('LABELS.new-password') }}</label>
                            <input type="password" id="new-pass" name="new-pass" class="person-form__input" v-model="passwords.new">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="new-pass-again" class="person-form__label">{{ $t('LABELS.repeat-new-password') }}</label>
                            <input type="password" id="new-pass-again" name="new-pass-again" class="person-form__input" v-model="passwords.newAgain">
                        </div>
                        <div class="person-form__input-wrapper">
                            <label for="old-pass" class="person-form__label">{{ $t('LABELS.original-password') }}</label>
                            <input type="password" id="old-pass" name="old-pass" class="person-form__input" v-model="passwords.old">
                        </div>
                    </div>
                </div>
                <button class="person-form__save-btn btn btn--orange btn--standalone" @click="save">{{ $t('LABELS.save') }}</button>
            </div>
        </section>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.loading-page')"></Loading>
    <Loading :is-loading="!isLoading && saving" :label="$t('STATUSES.saving-data')"></Loading>
    <Loading :is-loading="!isLoading && !saving && passwordChanging" :label="$t('STATUSES.saving-password')"></Loading>
</template>
<script>
import vSelect from "vue-select";
import { upperCaseFirst } from "upper-case-first";

import { Api } from '../modules/Api'
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import Notification from '../components/Notification.vue';
import Loading from '../components/Loading.vue';

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    data() {
        return {
            isLoading: false,
            saving: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            passwords: {
                new: '',
                newAgain: '',
                old: ''
            },
            userData: {
                firstName: '',
                lastName: '',
                sex: 'NotAvailable',
                nationality: location.host.split('.')[-1] === 'sk' ? 'sk' : 'cs',
                birthDate: '',
                email: '',
                prefix: '',
                phone: '',
            },
            error: {
                show: false,
                type: 'error',
                text: {
                    cs: [],
                    sk: []
                }
            },
            genderOptions: [
                {
                    code: 'Male',
                    text: this.$t('LABELS.male')
                },
                {
                    code: 'Female',
                    text: this.$t('LABELS.female')
                },
                {
                    code: 'NotAvailable',
                    text: this.$t('LABELS.sex-unspecified')
                }
            ],
            nationalityOptions: [
                {
                    code: 'cs',
                    text: this.$t('LABELS.czech-nationality'),
                },
                {
                    code: 'sk',
                    text: this.$t('LABELS.slovak-nationality'),
                }
            ],
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser'
        }
    },
    methods: {
        async loadDials() {
            const dials = await this.api.getDials();
            const wantedDials = {sex: 'genderOptions', nationality: 'nationalityOptions'}
            
            Object.keys(wantedDials).forEach(dialName => {
                const dial = dials[dialName]

                this[wantedDials[dialName]] = Object.keys(dial).map( key => {
                    return {
                        code: key,
                        text: upperCaseFirst(dial[key])
                    }
                })
            })
        },
        loadUserData() {
            const phone = this.$store.user.details.phone ? this.$store.user.details.phone : '';
            const phonePrefix = phone.replaceAll(' ', '').slice(0, phone.length - 9);
            const phoneNum = phone.replaceAll(' ', '').slice(-9);
            const nationality = this.$store.user.details.nationalityCode ? this.$store.user.details.nationalityCode : location.host.split('.')[-1] === 'sk' ? 'sk' : 'cs';
            const sex = this.$store.user.details.sex ? this.$store.user.details.sex : 'NotAvailable';

            this.userData.firstName = this.$store.user.details.firstName;
            this.userData.lastName = this.$store.user.details.lastName;
            this.userData.sex = sex;
            this.userData.nationality = nationality;
            this.userData.birthDate = this.$store.user.details.birthDate;
            this.userData.email = this.$store.user.details.email;
            this.userData.prefix = phonePrefix;
            this.userData.phone = phoneNum;
        },
        async save() {
            const updateData = {};

            this.closeNotification();

            updateData.first_name = this.userData.firstName;
            updateData.last_name = this.userData.lastName;
            updateData.sex = this.userData.sex;
            updateData.nationality_code = this.userData.nationality;
            updateData.birth_date = this.userData.birthDate;
            updateData.phone = this.userData.prefix + this.userData.phone;

            if (this.passwords.new.length > 0 || this.passwords.newAgain.length > 0) {
                if (this.passwords.new.length === 0 || this.passwords.newAgain.length === 0 || this.passwords.old.length === 0) {
                    this.error.show = true;
                    this.error.type = 'error';
                    this.error.text = {
                        cs: [this.$t('ERRORS.fill-password-required')],
                        sk: [this.$t('ERRORS.fill-password-required')]
                    };
                    window.scrollTo(0,0);
                    return false;
                }

                if (this.passwords.new !== this.passwords.newAgain) {
                    this.error.show = true;
                    this.error.type = 'error';
                    this.error.text = {
                        cs: [this.$t('ERRORS.not-same-passwords')],
                        sk: [this.$t('ERRORS.not-same-passwords')]
                    };
                    window.scrollTo(0,0);
                    return false;
                }

                updateData.password = this.passwords.new;
                
                this.passwordChanging = true;
                if (!(await this.$store.user.update(updateData, this.passwords.old))) {
                    this.passwordChanging = false;
                    this.error.show = true;
                    this.error.type = 'error';
                    this.error.text = {
                        cs: [this.$t('ERRORS.invalid-original-password')],
                        sk: [this.$t('ERRORS.invalid-original-password')]
                    };
                    window.scrollTo(0,0);
                    return false;
                }
                this.passwordChanging = false;

                this.passwords.new = '';
                this.passwords.newAgain = '';
                this.passwords.old = '';
            } else {
                this.saving = true;
                await this.$store.user.update(updateData);
                this.saving = false;
            }

            this.error.show = true;
            this.error.type = 'success';
            this.error.text = {
                cs: [this.$t('STATUSES.all-saved')],
                sk: [this.$t('STATUSES.all-saved')]
            };
            window.scrollTo(0,0);

            return true;
        },
        closeNotification() {
            this.error.show = false;
        },
    },
    async mounted() {
        if (!this.$store.user.isLogged) {
            this.$router.push('/prihlaseni/');
            return;
        }

        this.isLoading = true;
        await this.loadDials();
        this.isLoading = false;
        this.loadUserData();
    },
    components: {
        MainNav,
        Footer,
        vSelect,
        Notification,
        Loading,
        Head
    }
}
</script>