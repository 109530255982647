<template>
  <div
    class="yacht-reservation__part yacht-reservation__part--detail"
    :class="{ 'yacht-reservation__part--summary': summaryPage }"
  >
    <h4 class="yacht-reservation__subheading">
      <strong>{{ yachtDetails.country?.name[LANGUAGE] }}</strong> -
      {{ yachtDetails.destinationName?.[LANGUAGE] }}
    </h4>
    <h3 class="yacht-reservation__heading">
      {{ yachtDetails.name?.[LANGUAGE] }}
    </h3>
    <div class="yacht-reservation__badges">
      <div class="yacht-reservation__badge yacht-reservation__badge--orange">
        {{ yachtDetails.duration }}
        {{ inflectWord('days', yachtDetails.duration) }}
      </div>
      <div
        :class="`yacht-reservation__badge yacht-reservation__badge--${yachtDetails.theme}`"
      >
        <svg
          :class="`yacht-reservation__badge-icon yacht-reservation__badge-icon--${yachtDetails.theme}`"
        >
          <use
            :href="`/assets/imgs/icons/sprite.svg#${yachtDetails.theme}`"
          ></use>
        </svg>
        {{ yachtDetails.themeName?.[LANGUAGE] }}
      </div>
    </div>
    <div class="yacht-reservation__type">
      <svg class="yacht-reservation__type-icon">
        <use href="/assets/imgs/icons/sprite.svg#yacht"></use>
      </svg>
      {{ yachtDetails.typeName?.[LANGUAGE] }}
    </div>
  </div>
  <div class="yacht-reservation__part yacht-reservation__part--dates">
    <h3 class="yacht-reservation__date-heading">
      {{ $t('LABELS.sail-date') }}
    </h3>
    <p class="yacht-reservation__date-text">
      <span class="yacht-reservation__date-arrow">→</span>
      {{ formatDate(yachtDetails.departureDate) }}
      {{ yachtDetails.departureName?.[LANGUAGE] }}
    </p>
    <p class="yacht-reservation__date-text">
      <span class="yacht-reservation__date-arrow">←</span>
      {{ formatDate(yachtDetails.arrivalDate) }}
      {{ yachtDetails.arrivalName?.[LANGUAGE] }}
    </p>
  </div>
  <div
    class="yacht-reservation__part yacht-reservation__part--price"
    v-if="price"
  >
    <p class="yacht-reservation__price-text">
      <span class="yacht-reservation__price-label">{{
        $t('LABELS.overall-price')
      }}</span>
      {{ overallPriceText }}
    </p>
    <p
      class="
        yacht-reservation__text
        yacht-reservation__text--small
        yacht-reservation__text--left
      "
    >
      {{ $t('TEXTS.cabin-is-for-two-passengers') }}
    </p>
  </div>
  <div class="yacht-reservation__part yacht-reservation__part--more-info">
    <p
      class="
        yacht-reservation__text
        yacht-reservation__text--small
        yacht-reservation__text--left
      "
    >
      {{ $t('TEXTS.info-about-not-included-in-price') }}

      <a href="https://yachtas.cz/kontent/plavba-na-plachetnici">{{
        $t('TEXTS.can-be-found-here')
      }}</a
      >.
    </p>
  </div>
</template>

<script>
import formatPriceUtil from '../utilities/formatPrice';
import formatDateUtil from '../utilities/formatDate';
import { Dictionary } from '../modules/Dictionary';

import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
  data() {
    return {
      LANGUAGE: LANGUAGE,
    };
  },
  props: ['yachtDetails', 'price', 'summaryPage'],
  computed: {
    overallPriceText() {
      const price = this.price?.amount_decimal;
      return `${this.formatPrice(price)} ${this.price?.currency}`;
    },
  },
  methods: {
    formatPrice(price) {
      return formatPriceUtil(price);
    },
    formatDate(date) {
      return formatDateUtil(date);
    },
    inflectWord(word, num) {
      return dict.getWord(word, num, LANGUAGE);
    },
  },
};
</script>
