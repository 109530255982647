<template>
    <div id="review" class="review">
        
        <button
            class="review__btn review__btn--prev review__btn--cruises"
            :class="{
                'review__btn--disabled': swiper?.isBeginning || isLeftEnd
            }"
            @click="prevSlide"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="currentColor" viewBox="0 0 10 6" class="review__btn-icon review__btn-icon--left">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39375 0L10 0.646875L5 6L0 0.646875L0.603125 0L5 4.70312L9.39375 0Z" />
            </svg>
        </button>
        
        <Swiper
            :slides-per-view="1"
            :space-between="20"
            :auto-height="true"
            :breakpoints="{
                769: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
            }"
            class="review__slider reviews__slider"
            @swiper="saveSwiper"
        >
            <Swiper-slide
                v-for="review, i in reviews"
                :key="i"
            >
                <ReviewDash
                    :imageUrl="review.imageUrl"
                    :name="review.name?.[LANGUAGE]"
                    :text="review.text?.[LANGUAGE]"
                    :starsCount="review.stars_count"
                    @change-height="resetSwipper"
                ></ReviewDash>
            </Swiper-slide>
        </Swiper>
        
        <button
            class="review__btn review__btn--next review__btn--cruises"
            :class="{
                'review__btn--disabled': swiper?.isEnd || isRightEnd
            }"
            @click="nextSlide()"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="currentColor" viewBox="0 0 10 6" class="review__btn-icon review__btn-icon--right">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39375 0L10 0.646875L5 6L0 0.646875L0.603125 0L5 4.70312L9.39375 0Z" />
            </svg>
        </button>       
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from '../../node_modules/swiper/vue/swiper-vue';
import { TYPE, LANGUAGE } from '@/env';
import ReviewDash from './ReviewDash.vue';

export default {
    el: '#review',
    props: {
        reset: Number,
        reviews: Object,
    },
    components: {
        Swiper,
        SwiperSlide,
        ReviewDash
    },
    data() {
        return {
            TYPE: TYPE,
            LANGUAGE: LANGUAGE,
            swiper: null,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        saveSwiper(swiper) {
            this.swiper = swiper;
        },
        nextSlide() {
            this.swiper.slideNext();
        },
        prevSlide() {
            this.swiper.slidePrev();
        },
        resetSwipper() {
            setTimeout(() => {
                this.reSizeDash();
                this.swiper.update();
            }, 1);
        },
        reSizeDash() {
            this.$el.querySelectorAll('.review-dash').forEach(element => {
                element.style.minHeight = null;
            });

            let maxHeight = 0;
            this.$el.querySelectorAll('.swiper-slide').forEach(element => {
                let visibleLongText = false;
                element.querySelectorAll('.review-dash').forEach(item => {
                    if (item.classList.contains('review-dash-visible-long-text')) {
                        visibleLongText = true;
                    }
                });
                if (!visibleLongText && maxHeight < element.clientHeight) {
                    maxHeight = element.clientHeight;
                }
            });

            this.$el.querySelectorAll('.review-dash').forEach(element => {
                if (!element.classList.contains('review-dash-visible-long-text')) {
                    element.style.minHeight = `${maxHeight}px`;
                }
            });
        },
    },
    computed: {
        isRightEnd() {
            return this.swiper?.activeIndex + this.slidesPerView == this.reviews?.length || this.slidesPerView > this.reviews?.length;
        },
        isLeftEnd() {
            //! It's a trigger, don't manupulate with the console log
            console.log(this.reviews?.length);
            return this.swiper?.activeIndex === 0;
        },
        slidesPerView() {
            if (this.windowWidth >= 1025) {
                return 3;
            }
            if (this.windowWidth >= 769) {
                return 2;
            }
            return 1;
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
            this.resetSwipper();
        });

        this.resetSwipper();
    },
    watch: {
        reset() {
            this.swiper.slideReset();
        },
    }
}
</script>
<style scoped>
    .reviews__slider {
        height: initial;
    }
</style>