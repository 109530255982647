<template>
    <div class="review-dash" :class="{ 'review-dash__init-height': !showReadMore || !visibleLongText, 'review-dash-visible-long-text': visibleLongText }">
        <div class="review-dash__header">
            <div v-if="imageUrl.length"
                class="review-dash__image"
                :style="{
                    backgroundImage: `url('${imageUrl}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }"
            ></div>
            <div v-else class="review-dash__image_icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                </svg>
            </div>
            <div>
                <h3 class="review-dash__name">{{ name }}</h3>
                <div class="review-dash__stars">
                    <svg 
                        v-for="index in starsCount"
                        :key="index"
                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-star-fill review-dash__star" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="review-dash__text">
            <span v-html="shortText" v-if="this.showReadMore && !visibleLongText"></span>
            <span v-html="longText" v-if="!this.showReadMore || visibleLongText"></span>
            <span v-if="showReadMore && !visibleLongText" class="review-dash__show_more" @click="showMore()">číst více</span>
            <span v-if="showReadMore && visibleLongText" class="review-dash__show_less" @click="showLess()">Skrýt</span>
        </div>
    </div>
</template>
<script>

import { LANGUAGE } from '@/env'

export default {
    props: [ 'imageUrl', 'name', 'text', 'starsCount' ],
    data() {
        return {
            LANGUAGE: LANGUAGE,
            visibleLongText: false,
            showReadMore: false,
            shortText: '',
            longText: '',
        }
    },
    methods: {
        showMore() {
            this.visibleLongText = true;
            this.$emit('change-height');
        },
        showLess() {
            this.visibleLongText = false;
            this.$emit('change-height');
        },
    },
    mounted() {
        this.showReadMore = this.text.replace(/([&][a-z]*;)/gm, 'l').length > 280;

        if (this.showReadMore) {
            let substring = this.text.substring(0, 280);
            this.shortText = `${substring.substring(0, substring.lastIndexOf(' '))}...</p>`;
        } else {
            this.shortText = this.text;
        }
        this.longText = this.text;
    },
}
</script>