<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>

    <header class='header-detail'>
        <Main-nav type='yacht'></Main-nav>
    </header>
    <main class='main reservation'>
        <div class='reservation__inner'>
            <Notification :text='error.text[LANGUAGE]' v-if='error.show' :initConfig='{small: true}'
                          @closed='closeNotification'></Notification>
            <Reservation-nav v-else type='yacht' :currentLevel='3' :reservation-type='type'
                             :url-query='`?type=${type}&amount=${amount}`'></Reservation-nav>
            <div class='reservation__content reservation__content--final-yacht'>
                <div class='reservation__column reservation__column--summary'>
                    <h2 class='reservation__heading reservation__heading--yacht hide-mobile--large'>
                        {{ $t('LABELS.trip-info') }}
                    </h2>
                    <ReservationSide
                        class='hide-mobile--large'
                        :destination='yachtDetails.destinationName?.[LANGUAGE]'
                        :departurePort='yachtDetails.name?.[LANGUAGE]'
                        :departureDate='yachtDetails.departureDate'
                        :departureName='yachtDetails.departureName?.[LANGUAGE]'
                        :arrivalDate='yachtDetails.arrivalDate'
                        :arrivalName='yachtDetails.arrivalName?.[LANGUAGE]'
                        :duration='yachtDetails.duration'
                        :crewSummary='true'
                        :fullPrice='overallPrice'
                        :promo='promoInfo[$store.currency]'
                        :promoApplied='promoApplied'
                        :summaryList='summaryList'
                        type='yacht'
                        :yacht-type='yachtDetails.typeName?.[LANGUAGE]'
                        :theme='yachtDetails.theme'
                        :theme-name='yachtDetails.themeName?.[LANGUAGE]'
                    ></ReservationSide>
                </div>
                <div class='reservation__column reservation__column--promo'>
                    <h2 class='reservation__heading reservation__heading--yacht'>{{ $t('LABELS.promo-code') }}</h2>
                    <div class='person-form'>
                        <p class='reservation__promo-text' v-if='promoApplied'>
                            {{ $t('STATUSES.promo-successfully-applied', { priceText: `${formatPrice(promoInfo[$store.currency].amount_decimal)} ${promoInfo[$store.currency].currency}` })
                            }}</p>
                        <p class='reservation__promo-text' v-else-if='promoOnce'>{{ $t('ERRORS.bad-promo-code')
                            }}</p>
                        <input type='text' name='promo' v-model='promoCode'
                               class='person-form__input person-form__input--fullwidth person-form__input--promo'
                               maxlength='5'>
                        <button class='reservation__promo-btn btn btn--orange btn--standalone' @click='applyPromo'>
                            {{ $t('LABELS.apply-promo-code') }}
                        </button>
                    </div>
                </div>
                <form class='reservation__column reservation__column--pay' @submit.prevent=''>
                    <h2 class='reservation__heading reservation__heading--yacht'>{{ $t('LABELS.payment') }}</h2>
                    <div class='small-offer'>
                        <div class='small-offer__part'>
                            <h3 class='small-offer__heading small-offer__heading--yacht'>
                                {{ $t('LABELS.pay-everything-once') }}</h3>
                            <p class='small-offer__text'>{{ $t('TEXTS.pay-everything-note') }}</p>
                        </div>
                        <div class='small-offer__part small-offer__part--summary'>
                            <div class='small-offer__box'>
                                <p class='small-offer__price'>
                                    {{ formatPrice(finalPrice?.amount_decimal) }} {{ finalPrice?.currency }}</p>
                                <p class='small-offer__price-description'>{{ $t('TEXTS.pay-amount-at-once') }}</p>
                            </div>
                            <div class='small-offer__box small-offer__box--fill'>
                                <div class='small-offer__box__wrapper'>
                                    <button class='btn btn--orange small-offer__btn' :disabled='!allowGDPR'
                                            :class='{"btn--disabled": !allowGDPR}'
                                            @click="makeReservation('full')">{{ $t('LABELS.pay-with-bank') }}
                                    </button>
                                    <p class='small-offer__text'>{{ $t('TEXTS.pay-with-bank-description') }}</p>
                                </div>
                            </div>
                            <div class='small-offer__box small-offer__box--fill'>
                                <div class='small-offer__box__wrapper'>
                                    <button class='btn btn--orange small-offer__btn' :disabled='!allowGDPR'
                                            :class='{"btn--disabled": !allowGDPR}'
                                            @click="makeReservation('full', true)">{{ $t('LABELS.pay-with-card') }}
                                    </button>
                                    <p class='small-offer__text'>{{ $t('TEXTS.pay-with-card-description') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='small-offer' v-if='isDepositAvailable'>
                        <div class='small-offer__part'>
                            <h3 class='small-offer__heading small-offer__heading--yacht'>
                                {{ $t('LABELS.pay-50-deposit') }}</h3>
                            <p class='small-offer__text'>{{ $t('TEXTS.pay-50-deposit-note') }}</p>
                        </div>
                        <div class='small-offer__part small-offer__part--summary'>
                            <div class='small-offer__box'>
                                <p class='small-offer__price'>
                                    {{ formatPrice(finalPrice?.amount_decimal / 2) }} {{ finalPrice?.currency }}</p>
                                <p class='small-offer__price-description'>{{ $t('TEXTS.pay-amount-at-once') }}</p>
                                <p class='small-offer__price-without-transport'>
                                    ({{ $t('TEXTS.without-price-for-transport') }})</p>
                            </div>
                            <div class='small-offer__box small-offer__box--fill'>
                                <div class='small-offer__box__wrapper'>
                                    <button class='btn btn--orange small-offer__btn' :disabled='!allowGDPR'
                                            :class='{"btn--disabled": !allowGDPR}'
                                            @click="makeReservation('deposit')">
                                        {{ $t('LABELS.pay-with-bank') }}
                                    </button>
                                    <p class='small-offer__text'>{{ $t('TEXTS.pay-with-bank-description') }}</p>
                                </div>
                            </div>
                            <div class='small-offer__box small-offer__box--fill'>
                                <div class='small-offer__box__wrapper'>
                                    <button class='btn btn--orange small-offer__btn' :disabled='!allowGDPR'
                                            :class='{"btn--disabled": !allowGDPR}'
                                            @click="makeReservation('deposit', true)">{{ $t('LABELS.pay-with-card') }}
                                    </button>
                                    <p class='small-offer__text'>{{ $t('TEXTS.pay-with-card-description') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='checkbox-agree'>
                        <input type='checkbox' class='checkbox-agree__checkbox' id='gdpr-agree' v-model='allowGDPR'>
                        <label for='gdpr-agree' class='checkbox-agree__label'>{{ $t('TEXTS.agree-with-gdpr') }} <a
                            href='https://info.nautyy.cz/ochrana-soukromi/' class='checkbox-agree__link'
                            target='_blank'>{{ $t('TEXTS.GDPR') }}</a></label>
                    </div>
                </form>
                <div class='reservation__column reservation__column--note'>
                    <h3 class='reservation__note-heading-3'>{{ $t('LABELS.payment-terms-reservation') }}</h3>
                    <p class='reservation__note-text'>{{ $t('TEXTS.payment-terms-text') }}</p>
                    <ul class='reservation__list'>
                        <li class='reservation__list-item'>{{ $t('TEXTS.payment-note-1') }}</li>
                        <li class='reservation__list-item'>{{ $t('TEXTS.payment-note-2') }}</li>
                        <li class='reservation__list-item'>{{ $t('TEXTS.payment-note-3') }}</li>
                    </ul>
                    <h4 class='reservation__note-heading-4'>{{ $t('LABELS.cancellation-terms') }}</h4>
                    <ul class='reservation__list'>
                        <li class='reservation__list-item'>{{ $t('TEXTS.cancellation-note-1') }}</li>
                        <li class='reservation__list-item'>{{ $t('TEXTS.cancellation-note-2') }}</li>
                        <li class='reservation__list-item'>{{ $t('TEXTS.cancellation-note-3') }}</li>
                        <li class='reservation__list-item'>{{ $t('TEXTS.cancellation-note-4') }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </main>
    <Footer type='yachtas'></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-reservation-payment')"></Loading>
    <Loading :is-loading='!isLoading && applyingPromo' :label="$t('STATUSES.applying-promo')"></Loading>
    <Loading :is-loading='creatingReservation' :label="$t('STATUSES.creating-reservation')"></Loading>
    <LoginPage
        v-if='showLoginPopup'
        :is-popup='true'
        :full-window='true'
        extra-message='Před dokončením rezervace se prosím přihlašte.'
        @logged='checkLoggedUser'
    ></LoginPage>
</template>

<script>

import { upperCaseFirst } from 'upper-case-first';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import { Api } from '../../modules/Api';
import Notification from '../../components/Notification.vue';
import formatPriceUtil from '../../utilities/formatPrice';
import formatDate from '../../utilities/formatDate';
import Loading from '../../components/Loading.vue';
import LoginPage from "../LoginPage"

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';
import { yachtasStore } from '@/utilities/store/yachtas';
import * as _ from 'lodash';

export default {
    props: ['cruise_code'],
    data() {
        return {
            allowGDPR: true,
            showLoginPopup: false,
            isLoading: false,
            passengers: [],
            applyingPromo: false,
            creatingReservation: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            type: this.$route.query.type,
            amount: this.$route.query.amount,
            promoCode: '',
            promoInfo: { czk: {}, eur: {} },
            promoApplied: false,
            promoOnce: false,
            yachtDetails: {},
            companyBuy: false,
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                itinerary: {
                    cs: [],
                    sk: []
                },
                adultsNum: 2,
                childrenNum: 1,
                fullPrice: {
                    czk: {},
                    eur: {}
                },
                additionalCost: {
                    czk: {},
                    eur: {}
                },
                transportIncluded: true
            },
            workflow: {},
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: []
                }
            },
            types: {
                single: {
                    cs: this.$t('LABELS.bed'),
                    sk: this.$t('LABELS.bed')
                },
                cabin: {
                    cs: this.$t('LABELS.cabin'),
                    sk: this.$t('LABELS.cabin')
                },
                yacht: {
                    cs: this.$t('LABELS.ship'),
                    sk: this.$t('LABELS.ship')
                }
            }
        };
    },
    computed: {
        isDepositAvailable() {
            const today = new Date();
            const tripDate = new Date(this.yachtDetails?.departureDate);
            const todayTrip = new Date(this.yachtDetails?.departureDate);
            todayTrip.setMonth(today.getMonth());
            todayTrip.setFullYear(today.getFullYear());
            todayTrip.setDate(today.getDate());

            return (tripDate.getTime() - todayTrip.getTime()) > 60 * 24 * 60 * 60 * 1000;
        },
        overallPrice() {
            const price = { ...(this.yachtDetails.prices ? this.yachtDetails.prices[this.type][this.$store.currency] : {}) };

            price.amount_decimal *= this.amount;
            price.amount_int *= this.amount;

            return price;
        },
        finalPrice() {
            if (this.promoApplied) {
                const promo = this.promoInfo?.[this.$store.currency];
                return {
                    amount_decimal: this.overallPrice?.amount_decimal - promo?.amount_decimal,
                    amount_int: this.overallPrice?.amount_int - promo?.amount_int,
                    currency: this.overallPrice?.currency,
                    precision: this.overallPrice?.precision
                };
            }
            return this.overallPrice;
        },
        summaryList() {
            const list = [
                [
                    `${this.$t('LABELS.cruise-on-yacht')}, ${this.yachtDetails.country?.name[LANGUAGE]} ${formatDate(this.yachtDetails?.departureDate)} - ${formatDate(this.yachtDetails?.arrivalDate)}, ${this.amount}x ${this.types[this.type][LANGUAGE]}`,
                    `${this.formatPrice(this.overallPrice.amount_decimal)} ${this.overallPrice.currency}`
                ]
            ];

            if (this.promoApplied) {
                list.push([this.$t('LABELS.promo-code-discount'), `-${this.formatPrice(this.promoInfo[this.$store.currency].amount_decimal)} ${this.promoInfo[this.$store.currency].currency}`]);
            }

            return list;
        }
    },
    methods: {
        checkLoggedUser() {
            this.showLoginPopup = !this.$store.user.isLogged;
        },
        initPassengers() {
            this.passengers = yachtasStore.passengers.get(this.cruise_code);
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        async applyPromo() {
            this.applyingPromo = true;
            const promoInfo = await this.api.getPromoInfo(this.promoCode);
            this.applyingPromo = false;
            this.promoOnce = true;
            if (!promoInfo) {
                this.promoApplied = false;
                return false;
            }

            this.promoInfo = promoInfo;
            this.promoApplied = true;
        },
        async loadYachtDetail() {
            this.yachtDetails = await this.api.getYachtDetail(this.cruise_code);

            if (!this.yachtDetails) {
                return this.$router.push('/');
            }
        },
        async handleNotLoggedUser(responsiblePerson) {
            const userData = {};

            userData.first_name = responsiblePerson.firstName;
            userData.last_name = responsiblePerson.lastName;
            userData.phone = responsiblePerson.phone;

            const newAccountId = await this.api.tryRegisterAccount(responsiblePerson.email, userData);

            if (!newAccountId) {
                this.showLoginPopup = true;
                return false;
            }

            this.$store.user.details.id = newAccountId;
            return true;
        },
        async makeReservation(preferredPayment, payWithCard = false) {
            const people = _.map(this.passengers, passenger => {
                const transport = passenger.transport;

                return {
                    ...passenger,
                    ...transport
                };
            }).filter(passenger => passenger.type !== "responsible")

            const responsiblePerson = _.find(this.passengers, passenger => passenger.type === "responsible")

            const reqBody = {
                people,
                responsiblePassengerFirstName: responsiblePerson.firstName,
                responsiblePassengerLastName: responsiblePerson.lastName,
                responsiblePassengerEmail: responsiblePerson.email,
                responsiblePassengerPhone: responsiblePerson.prefix + responsiblePerson.phone,
                responsiblePassengerPlane: responsiblePerson.transport?.plane || false,
                responsiblePassengerCar: responsiblePerson.transport?.car || false,
                yachtCode: this.cruise_code,
                currency: this.$store.currency,
                buyAsCompany: this.companyBuy,
                promo: this.promoCode,
                preferredPayment: preferredPayment
            };

            const reservationType = this.$route.query.type === 'single' ? 'person' : this.$route.query.type;
            reqBody[`count${upperCaseFirst(reservationType)}`] = Number(this.$route.query.amount);

            if (!this.$store.user.isLogged) {
                if (!await this.handleNotLoggedUser(responsiblePerson)) {
                    return false;
                }
            }

            this.creatingReservation = true;
            const resp = await this.api.createReservation(this.$store.user.details.id, reqBody);

            this.creatingReservation = false;

            const query = {
                token: this.overview.workflowId
            };

            if (payWithCard && resp.data.order) {
                const { ticket_full, ticket_deposit } = resp.data.order;

                query.redirect = preferredPayment === 'full' ? ticket_full : ticket_deposit;
            }

            yachtasStore.passengers.reset();
            return this.$router.push({ path: `/rezervace/${this.cruise_code}/dokoncena/`, query });
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: []
            };
        },
        validateReservation() {
            const allowedTypes = ['single', 'cabin', 'yacht'];

            const validPassengers = _.size(this.passengers) > 0;

            if (!allowedTypes.includes(this.type)) {
                this.$router.push(`/detail/${this.cruise_code}/`);
                return false;
            }

            if (this.amount > this.yachtDetails.freeUnits[this.type] || !validPassengers) {
                const firstStepPath = {
                    single: 'rezervace-mist',
                    cabin: 'rezervace-kajut',
                    yacht: 'rezervace-lodi'
                };

                this.$router.push(`/rezervace/${this.cruise_code}/${firstStepPath[this.type]}/?type=${this.type}&amount=${this.amount}`);
            }
        }
    },
    async mounted() {
        this.isLoading = true;
        await Promise.all([this.loadYachtDetail()]);
        this.isLoading = false;
        this.initPassengers();
        this.validateReservation();
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        Notification,
        Loading,
        Head,
        ReservationSide,
        LoginPage
    }
};
</script>