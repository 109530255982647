<template>
  <div class="reservation-side" :class="type">
    <div class="reservation-side__part">
      <h4 class="reservation-side__subheading">{{ destination }}</h4>
      <h3 class="reservation-side__heading" v-if="type === 'yacht'">
        {{ departurePort }}
      </h3>
      <h3 class="reservation-side__heading" v-else>
        {{ $t('LABELS.departure-from') }} {{ departurePort }}
      </h3>
      <div
        :class="`reservation-side__badges reservation-side__badges--${
          type === 'yacht' ? 'yacht' : 'cruise'
        }`"
      >
        <div
          class="reservation-side__badge"
          :class="{ 'reservation-side__badge--yacht': type === 'yacht' }"
        >
          <strong>{{ duration }}</strong
          >&nbsp;{{ inflectWord('days', duration) }}
        </div>
        <div
          :class="`reservation-side__badge reservation-side__badge--${theme}`"
          v-if="type === 'yacht'"
        >
          <svg
            :class="`reservation-side__badge-icon reservation-side__badge-icon--${theme}`"
          >
            <use :href="`/assets/imgs/icons/sprite.svg#${theme}`"></use>
          </svg>
          <strong>{{ themeName }}</strong>
        </div>
        <div class="reservation-side__cruiser" v-if="type !== 'yacht'">
          <svg class="reservation-side__cruiser-icon">
            <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
          </svg>
          {{ cruiserName }}
        </div>
      </div>
      <div class="reservation-side__cruiser" v-if="type === 'yacht'">
        <svg
          class="
            reservation-side__cruiser-icon reservation-side__cruiser-icon--yacht
          "
        >
          <use href="/assets/imgs/icons/sprite.svg#yacht"></use>
        </svg>
        {{ yachtType }}
      </div>
      <p
        class="reservation-side__itinerary"
        v-if="type !== 'yacht'"
        v-html="getItineraryMarkup(this.itinerary)"
      ></p>
    </div>
    <div class="reservation-side__part">
      <div class="reservation-side__date-part">
        <h3 class="reservation-side__date-heading">
          {{ $t('LABELS.sail-date') }}
        </h3>
        <p class="yacht-reservation__date-text">
          <span class="yacht-reservation__date-arrow">→</span>
          {{ formatDate(departureDate) }}
          {{ departureName }}
        </p>
        <p class="yacht-reservation__date-text">
          <span class="yacht-reservation__date-arrow">←</span>
          <span>
            {{ formatDate(arrivalDate) }}
            {{ arrivalName }}
          </span>
        </p>
      </div>
    </div>
    <div class="reservation-side__part" v-show="!summaryPage">
      <h3 class="reservation-side__mini-heading">
        {{ $t('LABELS.cruise-crew-members-count') }}
      </h3>
      <div
        class="reservation-side__saved-info"
        v-show="crewSummary"
        v-for="(summaryItem, i) in summaryList"
        :key="i"
      >
        <span>{{ summaryItem[0] }}</span
        ><span class="nowrap">{{ summaryItem[1] }}</span>
      </div>
      <div class="reservation-side__person" v-if="!crewSummary">
        <p class="reservation-side__person-text">
          {{ $t('LABELS.adults-with-age') }}
        </p>
        <Counter
          :min="1"
          :max="maxAdults"
          :initial="adultsNum"
          @changed="updateAdultsNum"
        ></Counter>
      </div>
      <div class="reservation-side__person" v-if="!crewSummary">
        <p class="reservation-side__person-text">
          {{ $t('LABELS.children-with-age') }}
        </p>
        <Counter
          :min="0"
          :max="maxChildren"
          :initial="childrenNum"
          @changed="updateChildrenNum"
        ></Counter>
      </div>
    </div>
    <div
      class="reservation-side__part"
      v-if="crewSummary && !summaryPage && fullPrice"
    >
      <div class="reservation-side__final-price-part" v-if="fullPrice">
        <span class="reservation-side__final-price-label">{{
          $t('LABELS.overall-price')
        }}</span>
        <span class="reservation-side__final-price"
          >{{ formatPrice(finalPrice?.amount_decimal) }}
          {{ finalPrice?.currency }}</span
        >
      </div>
    </div>
    <div class="reservation-side__part" v-if="summaryPage && fullPrice">
      <div
        class="
          reservation-side__final-price-part
          reservation-side__final-price-part--highlight
        "
      >
        <span class="reservation-side__final-price-label">{{
          $t('LABELS.overall-price')
        }}</span>
        <span class="reservation-side__final-price"
          >{{ formatPrice(finalPrice?.amount_decimal) }}
          {{ finalPrice?.currency }}</span
        >
      </div>
    </div>
    <div class="reservation-side__part" v-if="summaryPage && portFee">
      <p class="reservation-side__note">
        {{
          $t('TEXTS.port-fee-note', {
            price: `${formatPrice(portFee?.amount_decimal)} ${
              portFee?.currency
            }`,
          })
        }}
      </p>
    </div>
    <div class="reservation-side__part" v-if="summaryPage">
      <p class="reservation-side__note">
        {{ $t('TEXTS.mandatory-cruise-fee-note') }}
      </p>
    </div>
    <div class="reservation-side__part">
      <p class="reservation-side__more-info">
        {{ $t('TEXTS.info-about-not-included-in-price') }}
        <a href="/jak-to-funguje/">{{
          $t('TEXTS.can-be-found-here')
        }}</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
import Counter from '../components/Counter.vue';
import { Dictionary } from '../modules/Dictionary';
import formatPriceUtil from '../utilities/formatPrice';
import formatDateUtil from '../utilities/formatDate';
import formatItineraryMarkupUtil from '../utilities/formatItineraryMarkup';
import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
  components: {
    Counter,
  },
  props: [
    'destination',
    'departurePort',
    'departureName',
    'arrivalName',
    'duration',
    'itinerary',
    'departureDate',
    'arrivalDate',
    'adultsNum',
    'childrenNum',
    'crewSummary',
    'fullPrice',
    'portFee',
    'summaryList',
    'summaryPage',
    'type',
    'yachtType',
    'theme',
    'themeName',
    'cruiserName',
    'promo',
    'promoApplied',
  ],
  data() {
    return {
      adultsNumInter: this.adultsNum,
      childrenNumInter: this.childrenNum,
    };
  },
  methods: {
    formatPrice(price) {
      return formatPriceUtil(price);
    },
    formatDate(date) {
      return formatDateUtil(date);
    },
    inflectWord(word, num) {
      return dict.getWord(word, num, LANGUAGE);
    },
    getItineraryMarkup(itinerary) {
      return formatItineraryMarkupUtil(itinerary);
    },
    updateAdultsNum(newAdultsNum) {
      this.adultsNumInter = newAdultsNum;
      this.$emit('updatedAdults', newAdultsNum);
    },
    updateChildrenNum(newChildrenNum) {
      this.childrenNumInter = newChildrenNum;
      this.$emit('updatedChildren', newChildrenNum);
    },
  },
  computed: {
    maxAdults() {
      return 4 - this.childrenNumInter;
    },
    maxChildren() {
      return 4 - this.adultsNumInter;
    },
    finalPrice() {
      if (this.promoApplied) {
        return {
          amount_decimal:
            this.fullPrice?.amount_decimal - this.promo?.amount_decimal,
          amount_int: this.fullPrice?.amount_int - this.promo?.amount_int,
          currency: this.fullPrice?.currency,
          precision: this.fullPrice?.precision,
        };
      }
      return this.fullPrice;
    },
  },
};
</script>
