import camelcase from 'camelcase';
import { upperCaseFirst } from 'upper-case-first';
import { Api } from './Api'

export class User {
    constructor() {
        this.api = new Api();
        this.isLogged = false;
        this.details = {};
        this.storageName = 'yachtas-user';
    }
    
    async init() {
        await this.trySavedLogin();
    }

    async trySavedLogin() {
        let storedUser;

        const storedUserJSON = localStorage.getItem(this.storageName);
        if (!storedUserJSON) return false;

        try {
            storedUser = JSON.parse(storedUserJSON);
        } catch {
            return false;
        }

        const { email, loginToken } = storedUser;
        if (!email || !loginToken) {
            return false;
        }
        return await this.login(email, loginToken);
    }

    async login(email, password, store = true) {
        this.logout();
    
        const resp = await this.api.loginUser(email, password)

        if (!resp) return false;

        const userObj = {};
        Object.keys(resp).forEach(key => {
            userObj[camelcase(key)] = resp[key];
        })
        
        this.details = userObj;
        this.isLogged = true;
        if (store) this.storeDetails();

        return true;
    }

    logout(stored = true) {
        this.isLogged = false;
        this.details = {};
        if (stored) localStorage.removeItem(this.storageName);
    }

    async update(data, password = this.details.loginToken) {
        const resp = await this.api.updateUser(this.details.email, password, data);

        if (!resp) return false;

        Object.keys(data).forEach(key => {
            this.details[camelcase(key)] = data[key];
        })

        this.storeDetails();

        return true;
    }

    storeDetails() {
        if (!this.isLogged) return false;

        localStorage.setItem(this.storageName, JSON.stringify(this.details));

        return true;
    }

    shortenUsername(username, limit = 15) {
        if (username.length <= limit) return username;

        return `${username.slice(0, limit - 3)}...`
    }

    getUsername(short = false) {
        if (!this.isLogged) return false;

        let username = this.details.firstName ? upperCaseFirst(this.details.firstName) : this.details.email.split('@')[0];

        return short ? this.shortenUsername(username, 15) : username;
    }
}