<template>
    <Template
        type="yacht"
        heading-text="Nadpis H1 uprostřed fotky"
        image-url="/assets/imgs/templates/hero.png"
        :quote-text='[
            "Velký nápis typu quote",
            "Další řádek v nápisu quote",
        ]'
        orange-btn-text="Button Orange"
        orange-btn-link="/"
        blue-btn-text="Blue Orange"
        blue-btn-link="/"
    >
        <!-- WRITE CUSTOM HTML BELOW ME -->
        <h2 class="template__h2">Nadpis H2</h2>
        <p class="template__p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed turpis ultricies lorem porta interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at sollicitudin orci, ut tempus mauris. Fusce mattis quam at dui lobortis tristique. Cras et mattis ligula. Duis scelerisque mattis sapien, sed imperdiet mauris facilisis a. Aenean maximus libero nulla, nec blandit nisl interdum sed. Pellentesque vulputate lobortis dignissim. Pellentesque nec arcu eget libero euismod sodales eget in purus.</p>
        <br>
        <h3 class="template__h3">Nadpis H3</h3>
        <p class="template__p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed turpis ultricies lorem porta interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at sollicitudin orci, ut tempus mauris. Fusce mattis quam at dui lobortis tristique. Cras et mattis ligula. Duis scelerisque mattis sapien, sed imperdiet mauris facilisis a. Aenean maximus libero nulla, nec blandit nisl interdum sed. Pellentesque vulputate lobortis dignissim. Pellentesque nec arcu eget libero euismod sodales eget in purus.</p>
        <br>
        <h2 class="template__h2">Nadpis H2</h2>
        <ul class="template__ul">
            <li class="template__li">Ukázka UL LI seznamu</li>
            <li class="template__li">Prenájom katamaránu</li>
            <li class="template__li">Poistenie kaucie</li>
            <li class="template__li">Čisté obliečky</li>
        </ul>
    </Template>
</template>
<style lang="scss" scoped>
// WRITE CUSTOM CSS BELOW ME

</style>
<script>
// WRITE CUSTOM JS BELOW ME
// This is not scoped for your template page, so be carefull











// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!! DO NOT TOUCH CODE BELOW !!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import Template from '../Template.vue'

export default {
    components: { Template }
}
</script>