<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.contact') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.contact') })" />
    </Head>
    <header :style="{display: 'flex', flexDirection: 'column', minHeight: '100vh'}">
        <Main-nav></Main-nav>
        <Content-hero
            :style="{flex: 1}"
            background-img-url="/assets/imgs/content-headers/contact.jpg"
            :title="$t('LABELS.contact')"
            :sub-title="$t('TEXTS.carefree-cruise')"
        />
        <Content-under-hero :text="$t('TEXTS.we-are-here-for-any-question')" :show-arrow="false" />
    </header>
    <main class="main content-pages__main">
        <Contact-dash />
    </main>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContactDash from '../../components/ContactDash';
import Footer from '../../components/Footer';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContactDash
    }
}
</script>