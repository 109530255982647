<template>
  <Head>
    <title>{{ $t('TITLES.homepage') }}</title>
    <meta name="description" :content="$t('META-DESCRIPTIONS.homepage')" />
  </Head>

  <header class="header-search header-search--cruiser">
    <div class="header-search__background header-search__background--cruiser">
      <video
        class="header-search__background-video"
        ref="video"
        src="/assets/videos/nautyy.mp4"
        muted
        autoplay
        loop
      ></video>
    </div>
    <Main-nav></Main-nav>
    <div class="main-search__container">
      <h1 class="main-search__heading-upper">
        {{ $t('TEXTS.cruise-desktop-search-note') }}
      </h1>
      <section class="main-search">
        <header class="main-search__header">
          <h1
            class="
              main-search__header-heading main-search__header-heading--longer
            "
          >
            {{ $t('TEXTS.find-journey-on-cruiser') }}
          </h1>
          <h1
            class="
              main-search__header-heading main-search__header-heading--shorter
            "
          >
            {{ $t('TEXTS.experience-cruise') }}
          </h1>
        </header>
        <main class="main-search__main">
          <div class="main-search__input-wrapper">
            <svg
              class="
                main-search__input-icon
                main-search__input-icon--pinpoint
                main-search__input-icon--cruiser
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#pinpoint"></use>
            </svg>
            <label for="destination" class="main-search__label">{{
              $t('LABELS.where-to')
            }}</label>
            <v-select
              class="main-search__select main-search__select--cruiser"
              :options="langDestinations"
              :reduce="(destination) => destination.code"
              label="langName"
              v-model="destination"
            ></v-select>
          </div>
          <div class="main-search__input-wrapper">
            <svg
              class="
                main-search__input-icon
                main-search__input-icon--calendar
                main-search__input-icon--cruiser
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#calendar"></use>
            </svg>
            <label for="start-date" class="main-search__label">{{
              $t('LABELS.date-from')
            }}</label>
            <input
              type="date"
              id="start-date"
              class="main-search__input main-search__input--clickable"
              v-model="startDate"
            />
          </div>
          <div class="main-search__input-wrapper">
            <svg
              class="
                main-search__input-icon
                main-search__input-icon--calendar
                main-search__input-icon--cruiser
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#calendar"></use>
            </svg>
            <label for="end-date" class="main-search__label">{{
              $t('LABELS.date-to')
            }}</label>
            <input
              type="date"
              id="end-date"
              class="main-search__input main-search__input--clickable"
              v-model="endDate"
            />
          </div>
          <div
            class="main-search__input-wrapper"
            v-click-outside="hideCrewPopup"
            @click="showCrewPopup"
          >
            <svg
              class="
                main-search__input-icon
                main-search__input-icon--people
                main-search__input-icon--cruiser
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#people"></use>
            </svg>
            <label for="crew-members" class="main-search__label">{{
              $t('LABELS.crew')
            }}</label>
            <input
              type="text"
              id="crew-members"
              class="
                main-search__input
                main-search__input--crew
                main-search__input--clickable
              "
              :value="`${adultsNum} ${adultsInflected}, ${childrenNum} ${childrenInflected}`"
              readonly
            />
            <div
              class="main-search__popup main-search__popup--crew"
              v-show="isCrewPopupShown"
            >
              <Counter
                :min="1"
                :max="adultsLimit"
                :initial="adultsNum"
                :title="$t('LABELS.adults-with-age')"
                @changed="updateAdultsNum"
              ></Counter>
              <Counter
                :min="0"
                :max="childrenLimit"
                :initial="childrenNum"
                :title="$t('LABELS.children-with-age')"
                @changed="updateChildrenNum"
              ></Counter>
            </div>
          </div>
          <!-- <div class="main-search__input-wrapper main-search__input-wrapper--checkbox">
                        <div class="main-search__checkbox-wrapper">
                            <input type="checkbox" id="transport-included" class="main-search__checkbox" v-model="transportIncluded">
                        </div>
                        <label for="transport-included" class="main-search__checkbox-label">
                            <svg class="main-search__input-icon main-search__input-icon--car main-search__input-icon--cruiser"><use href="/assets/imgs/icons/sprite.svg#car"></use></svg>
                            <p class="main-search__label main-search__label--checkbox">{{ $t('LABELS.includes-transport) }}</p>
                        </label>
                    </div> -->
          <button
            class="main-search__search-btn btn btn--standalone-small btn--blue"
            @click="search"
          >
            <svg class="main-search__search-icon">
              <use href="/assets/imgs/icons/sprite.svg#search"></use>
            </svg>
            <span class="main-search__search-btn-label">{{
              $t('LABELS.search-cruise')
            }}</span>
          </button>
        </main>
      </section>
      <Banner
        class="header-search__banner"
        :type="banner.desktop.type"
        :type-mobile="banner.mobile.type"
        :source="banner.desktop.source"
        :source-mobile="banner.mobile.source"
        :url="banner.url"
      >
      </Banner>
    </div>
  </header>
  <main class="main main--cruiser">
    <!-- <section class="section section--light-blue">
            <div class="section__inner video-showcase__container">
                <div class="video-showcase video-showcase--single">
                    <VideoPlayer
                        video-url="/assets/videos/cruisers.mov"
                        poster-url="/assets/imgs/posters/cruiser-overview.jpg"
                    ></VideoPlayer>
                </div>
            </div>
        </section> -->
    <section class="section">
      <div class="section__inner">
        <h2 class="section__heading section__heading--cruiser">
          {{ $t('LABELS.destination') }}
        </h2>
        <div class="small-showcase">
          <router-link
            :to="loadPromoCruisesUrl"
            class="small-showcase__item"
            key="promo-cruises"
          >
            <img
              src="/assets/imgs/destinations/action.jpeg"
              :alt="$t('LABELS.promo-cruises')"
              class="small-showcase__img"
            />
            <p class="small-showcase__txt">{{ $t('LABELS.promo-cruises') }}</p>
          </router-link>

          <router-link
            :to="`/vyhledat/#destination=${destination.code}`"
            class="small-showcase__item"
            v-for="destination in destinations.filter((dest) => !dest.added)"
            :key="destination.code"
          >
            <img
              :src="destination.photo_url"
              :alt="destination.name[LANGUAGE]"
              class="small-showcase__img"
            />
            <p class="small-showcase__txt">{{ destination.name[LANGUAGE] }}</p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="section" v-if="this.promoCruises.length > 0">
      <div class="section__inner">
        <h2 class="section__heading section__heading--cruiser">
          {{ $t('LABELS.promo-cruises') }}
        </h2>
        <div class="large-showcase">
          <Showcase-item
            v-for="cruise in promoCruises"
            :key="cruise.cruise_id"
            type="cruiser"
            :duration="cruise.days"
            :transport-included="cruise.transport_included"
            :image-url="cruise.image"
            :departure-city="cruise.departure_port_name[LANGUAGE]"
            :destination="cruise.destination_name[LANGUAGE]"
            :itinerary="cruise.itinerary[LANGUAGE]"
            :departure-date="cruise.departure_date"
            :arrival-date="cruise.arrival_date"
            :price="cruise.singleForTwoPrice[$store.currency]"
            :price-full="cruise.fullPrice[$store.currency]"
            :second-price="cruise.priceDouble[$store.currency]"
            :second-price-label="$t('TEXTS.price-for-two-from')"
            :code="cruise.cruise_code"
            :is-single-price="true"
            :cruiser-name="cruise.ship_name[LANGUAGE]"
            :style="{ display: 'flex', flexDirection: 'column' }"
            :promo-name="cruise.promo_name"
          ></Showcase-item>
        </div>
        
        <div class="btn--promo-wrap">
          <router-link
            :to="loadPromoCruisesUrl"
            class="btn btn--promo btn--blue"
          >
            {{ $t('LABELS.show-all-promo-cruises') }}
          </router-link>
        </div>
      </div>
    </section>
    
    <section class="section" v-if="reviews.length">
      <div class="section__inner">
        <h2 class="section__heading section__heading--cruiser">{{ $t('LABELS.review-talking') }}</h2>
        <Reviews :reviews='reviews'></Reviews>

        <div class="section__more-buttons">
            <a href="https://g.page/r/CaiP47Fy-uSuEBM/review" target="_blank" class="btn btn--orange">{{ $t('LABELS.more-google-reviews') }}</a>
            <a href="https://www.facebook.com/costacruises.cz.sk/reviews" target="_blank" class="btn btn--blue">{{ $t('LABELS.more-fb-reviews') }}</a>
        </div> 
      </div>
    </section>
  </main>
  <Footer></Footer>
  <Loading
    :is-loading="isLoading"
    :label="$t('STATUSES.loading-page')"
  ></Loading>
</template>

<script>
import vSelect from 'vue-select';
import moment from 'moment';
import ClickOutside from '../../modules/vendor/vue-click-outside';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import Counter from '../../components/Counter';
import { Dictionary } from '../../modules/Dictionary';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';
import ShowcaseItem from '../../components/ShowcaseItem';
import Reviews from '../../components/Reviews';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
  components: {
    Footer,
    MainNav,
    Counter,
    vSelect,
    Loading,
    Banner,
    Head,
    ShowcaseItem,
    Reviews,
  },
  data() {
    return {
      isLoading: false,
      api: new Api(this.$t),
      LANGUAGE: LANGUAGE,
      isCrewPopupShown: false,
      isDatePopupShown: false,
      adultsNum: 2,
      childrenNum: 0,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment(
        new Date(new Date().setYear(new Date().getFullYear() + 2))
      ).format('YYYY-MM-DD'),
      transportIncluded: false,
      destinations: [],
      favoriteCruises: [],
      promoCruises: [],
      destination: '',
      banner: { url: '', mobile: {}, desktop: {} },
      reviews: [],
    };
  },
  computed: {
    adultsLimit() {
      return 4 - this.childrenNum;
    },
    childrenLimit() {
      return 4 - this.adultsNum;
    },
    adultsInflected() {
      return dict.getWord('adults', this.adultsNum);
    },
    childrenInflected() {
      return dict.getWord('children', this.childrenNum);
    },
    langDestinations() {
      const langDestinations = this.destinations.map((destination) => {
        destination.langName = destination.name[LANGUAGE];
        return destination;
      });

      return langDestinations;
    },
    loadPromoCruisesUrl() {
      let searchHash = '#';

      searchHash += `destination=${this.destination}&`;
      searchHash += `startDate=${this.startDate}&`;
      searchHash += `endDate=${this.endDate}&`;
      searchHash += `adultsNum=${this.adultsNum}&`;
      searchHash += `childrenNum=${this.childrenNum}&`;
      searchHash += `transportIncluded=${this.transportIncluded}&`;
      searchHash += 'onlyPromo=true';

      return `/vyhledat/${searchHash}`;
    },
  },
  methods: {
    handleShownPrice(cruises) {
      cruises.forEach((cruise) => {
        cruise.singleForTwoPrice = JSON.parse(
          JSON.stringify(cruise.priceDouble)
        );
        Object.keys(cruise.singleForTwoPrice).forEach((currency) => {
          cruise.singleForTwoPrice[currency].amount_decimal /= 2;
          cruise.singleForTwoPrice[currency].amount_int /= 2;
        });

        cruise.fullPrice = JSON.parse(
          JSON.stringify(cruise.full_price)
        )
        Object.keys(cruise.fullPrice).forEach((currency) => {
          cruise.fullPrice[currency].amount_decimal /= 2;
          cruise.fullPrice[currency].amount_int /= 2;
        });
      });
    },
    hideCrewPopup() {
      if (this.isCrewPopupShown === true) {
        this.isCrewPopupShown = false;
      }
    },
    showCrewPopup() {
      if (this.isCrewPopupShown === false) {
        this.isCrewPopupShown = true;
      }
    },
    hideDatePopup() {
      if (this.isDatePopupShown === true) {
        this.isDatePopupShown = false;
      }
    },
    showDatePopup() {
      if (this.isDatePopupShown === false) {
        this.isDatePopupShown = true;
      }
    },
    updateAdultsNum(num) {
      this.adultsNum = num;
    },
    updateChildrenNum(num) {
      this.childrenNum = num;
    },
    search() {
      let searchHash = '#';

      searchHash += `destination=${this.destination}&`;
      searchHash += `startDate=${this.startDate}&`;
      searchHash += `endDate=${this.endDate}&`;
      searchHash += `adultsNum=${this.adultsNum}&`;
      searchHash += `childrenNum=${this.childrenNum}&`;
      searchHash += `transportIncluded=${this.transportIncluded}`;

      this.$router.push(`/vyhledat/${searchHash}`);
    },
    async loadReviews() {
      this.reviews = await this.api.getReviews(TYPE, LANGUAGE);
    },
    async loadDestinations() {
      this.destinations = await this.api.getDestinations();
    },
    async loadPromoCruises() {
      const promoCruises = await this.api.getPromoCruises();
      this.handleShownPrice(promoCruises);
      this.promoCruises = promoCruises;
    },
    async loadBanner() {
      this.banner =
        (await this.api.getBanner(TYPE, LANGUAGE, 'homepage')) || this.banner;
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.loadBanner(),
        this.loadDestinations(),
        this.loadPromoCruises(),
        this.loadReviews(),
      ]);
    } catch {
      this.isLoading = false;
    }
    this.isLoading = false;
  },
  watch: {
    destinations(newVal) {
      this.destination = newVal[0].name[LANGUAGE];
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
