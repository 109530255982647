import { createApp, reactive } from 'vue';
import { createI18n } from 'vue-i18n';
import { createHead } from '@vueuse/head';
import VueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';

import { User } from './modules/User';
import * as router from './router';
import { TYPE, LANGUAGE, VUE_APP_GTM_TOKEN } from '@/env';

import localesCzech from './locales/cs.json';
import localesSlovak from './locales/sk.json';

import '../node_modules/vue-select/dist/vue-select.css';
import '../node_modules/swiper/swiper-bundle.min.css';
import '../node_modules/@fancyapps/ui/dist/fancybox.css';

import App from './App.vue';

(async () => {
  const currencies = {
    cs: 'czk',
    sk: 'eur',
  };

  const store = reactive({
    currency: currencies[LANGUAGE],
    user: new User(),
  });

  const contact = {
    yachtas: {
      cs: {
        phone: '+420 604 290 820',
        email: 'plavby@yachtas.cz',
        instagram: 'https://www.instagram.com/yachtas_official/',
        facebook: 'https://www.facebook.com/yachtascz/',
        website: 'https://yachtas.cz',
      },
      sk: {
        phone: '+421 910 626 222',
        email: 'plavby@yachtas.sk',
        instagram: 'https://www.instagram.com/yachtas_official/',
        facebook: 'https://www.facebook.com/yachtascz/',
        website: 'https://yachtas.sk',
      },
    },
    cruises: {
      cs: {
        phone: '+420 731 562 127',
        email: 'plavby@nautyy.cz',
        instagram: 'https://www.instagram.com/nautyy_ck/',
        facebook: 'https://www.facebook.com/costacruises.cz.sk',
        website: 'https://nautyy.cz',
      },
      sk: {
        phone: '+421 910 626 222',
        email: 'plavby@nautyy.sk',
        instagram: 'https://www.instagram.com/nautyy_ck/',
        facebook: 'https://www.facebook.com/costacruises.cz.sk',
        website: 'https://nautyy.sk',
      },
    },
    phone: {
      cs: '+420 111 222 333',
      sk: '+421 111 222 333',
    },
  };

  const messages = {
    cs: localesCzech,
    sk: localesSlovak,
  };
  const i18n = createI18n({
    locale: LANGUAGE, // set locale
    fallbackLocale: 'cs', // set fallback locale
    messages,
  });

  // User global object
  await store.user.init();

  const app = createApp(App);
  const head = createHead();

  app.config.globalProperties.$store = store;
  app.config.globalProperties.$globalContact = contact;
  app.use(router[TYPE]);
  app.use(i18n);
  app.use(head);
  app.use(VueCookies, { expires: '30d'});
  app.use(VueGtag, {
    config: {id: VUE_APP_GTM_TOKEN}
  })

  app.mount('#app');
})();
