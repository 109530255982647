<template>
    <nav class="reservation-nav" :class="{'reservation-nav--yacht': type === 'yacht'}">
        <p class="reservation-nav__dash-label">{{ currentLevel + 1 }}/{{ navLevels.length }} {{ navLevels[currentLevel].title }}</p>
        <div class="reservation-nav__step-wrapper" v-for="(level, i) in navLevels" :key="i">
            <router-link
                v-if="i <= currentLevel"
                :to="level.url"
                class="reservation-nav__step"
                :class="{
                    'reservation-nav__step--checked': i < currentLevel,
                    'reservation-nav__step--active': i === currentLevel
                }"
            >
                <svg class="reservation-nav__checked-icon" v-show="i <= currentLevel">
                    <use href="/assets/imgs/icons/sprite.svg#tick"></use>
                </svg>
                <span
                    class="reservation-nav__label"
                    :class="{'reservation-nav__label--active': i === currentLevel}"
                >{{ level.title }}</span>
            </router-link>
            <div class="reservation-nav__step" v-else>
                <span class="reservation-nav__num">{{ i > currentLevel ? i + 1 : '' }}</span>
                <span class="reservation-nav__label">{{ level.title }}</span>
            </div>
            <div class="reservation-nav__arrow" v-show="i < navLevels.length - 1">
                <svg class="reservation-nav__arrow-icon">
                    <use href="/assets/imgs/icons/sprite.svg#triangle-right"></use>
                </svg>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        currentLevel: {},
        type: {
            default: 'cruiser'
        },
        reservationType: String,
        urlQuery: {
            default: ''
        },
    },
    data() {
        return {
            cruise_code: '',
            reservationTypesUnits: {
                single: {
                    title: this.$t('LABELS.single-reservation'),
                    path: '/rezervace-mist/'
                },
                cabin: {
                    title: this.$t('LABELS.cabin-reservation'),
                    path: '/rezervace-kajut/'
                },
                yacht: {
                    title: this.$t('LABELS.yacht-reservation'),
                    path: '/rezervace-lodi/'
                },
            }
        }
    },
    computed: {
        navLevels() {
            return this.type === 'cruiser' ? [
                {
                    title: this.$t('LABELS.destination'),
                    url: `/detail/${this.cruise_code}/`
                },
                {
                    title: this.$t('LABELS.cabin-and-accomodation-package'),
                    url: `/rezervace/${this.cruise_code}/kajuta-a-ubytovaci-balicek/${this.urlQuery}`
                },
                // {
                //     title: this.$t('LABELS.cabin'),
                //     url: `/rezervace/${this.cruise_code}/kajuta/${this.urlQuery}`
                // },
                {
                    title: this.$t('LABELS.transport'),
                    url: `/rezervace/${this.cruise_code}/doprava/${this.urlQuery}`
                },
                {
                    title: this.$t('LABELS.summary'),
                    url: `/rezervace/${this.cruise_code}/shrnuti/${this.urlQuery}`
                },
                {
                    title: this.$t('LABELS.payment'),
                    url: `/rezervace/${this.cruise_code}/platba/${this.urlQuery}`
                }
            ] : (this.type === 'yacht' ? [
                {
                    title: this.$t('LABELS.destination'),
                    url: `/detail/${this.cruise_code}/`
                },
                {
                    title: this.reservationTypesUnits[this.reservationType]?.title,
                    url: `/rezervace/${this.cruise_code}${this.reservationTypesUnits[this.reservationType]?.path}${this.urlQuery}`
                },
                {
                    title: this.$t('LABELS.summary'),
                    url: `/rezervace/${this.cruise_code}/shrnuti/${this.urlQuery}`
                },
                {
                    title: this.$t('LABELS.payment'),
                    url: `/rezervace/${this.cruise_code}/platba/${this.urlQuery}`
                },
            ] : [])
        }
    },
    mounted() {
        this.cruise_code = this.$route.params.cruise_code
    }
}
</script>