<template>
    <div v-for='dash in dashesWithIds' :key='dash.id'>
        <img v-if="dash.type.includes('image')" :src="dash.src" @click='showLightbox(dash.id)'>
        <img v-else-if="dash.type.includes('video')" :src="dash.thumb" @click='showLightbox(dash.id)'>
        <a v-else-if="dash.type.includes('pdf')" :href="dash.src" target="_blank"><img :src="dash.thumb"></a>
    </div>

    <Fancybox
        :data='this.dashesWithIds'
        :visible='lightbox.shown'
        :selectedId='lightbox.selectedId'
        @closed='() => lightbox.shown = false'
    />
</template>
<script>
import Fancybox from '@/components/Fancybox';
import { withGeneratedIds } from '@/utilities';

export default {
    props: [ 'dashes', 'withLightbox' ],
    components: {
        Fancybox,
    },
    data() {
      return {
          lightbox: {
              selectedId: "",
              shown: false
          },
      }
    },
    computed: {
      dashesWithIds() {
          return withGeneratedIds(this.dashes)
      }
    },
    methods: {
        showLightbox(id) {
            if(!this.withLightbox) return;

            this.lightbox.selectedId = id;
            this.lightbox.shown = true;
        }
    }
}
</script>