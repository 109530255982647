export class Dictionary {
    constructor() {

    }

    languages = {
        cs: {
            adults: { default: "dospělých", 1: "dospělý", 2: "dospělí", 3: "dospělí", 4: "dospělí" },
            children: { default: "dětí", 1: "dítě", 2: "děti", 3: "děti", 4: "děti" },
            days: { default: "dní", 1: "den", 2: "dny", 3: "dny", 4: "dny" },
            guests: { default: "hostů", 1: "hosta", 2: "hosty", 3: "hosty", 4: "hosty" },
            person: { default: "osob", 1: "osoba", 2: "osoby", 3: "osoby", 4: "osoby" },
            freeMid: { default: "volných", 1: "volné", 2: "volná", 3: "volná", 4: "volná" },
            place: { default: "míst", 1: "místo", 2: "místa", 3: "místa", 4: "místa" },
            left: { default: "zbývá", 2: "zbývají", 3: "zbývají", 4: "zbývají" },
            cruises: { default: "zájezdů", 1: "zájezd", 2: "zájezdy", 3: "zájezdy", 4: "zájezdy" },
        },
        sk: {
            adults: { default: "dospelých", 1: "dospelý", 2: "dospelí", 3: "dospelí", 4: "dospelí" },
            children: { default: "detí", 1: "dieťa", 2: "deti", 3: "deti", 4: "deti" },
            days: { default: "dní", 1: "deň", 2: "dni", 3: "dni", 4: "dni" },
            guests: { default: "hostí", 1: "hosť", 2: "hostia", 3: "hostia", 4: "hostia" },
            person: { default: "osôb", 1: "osoba", 2: "osoby", 3: "osoby", 4: "osoby" },
            freeMid: { default: "voľných", 1: "voľné", 2: "voľná", 3: "voľná", 4: "voľná" },
            place: { default: "miest", 1: "miesto", 2: "miesta", 3: "miesta", 4: "miesta" },
            left: { default: "zostáva", 2: "zostávajú", 3: "zostávajú", 4: "zostávajú" },
            cruises: { default: "zájazdov", 1: "zájazd", 2: "zájazdy", 3: "zájazdy", 4: "zájazdy" },
        }
    }

    getWord(word, num, lang = 'cs') {
        if (!this.languages[lang] || !this.languages[lang][word]) {
            return word
        }

        const wordDict = this.languages[lang][word];
        return wordDict[num] ? wordDict[num] : wordDict.default;
    }
}