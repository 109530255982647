<template>
    <header class="header-search" :style="`background-image: url(${ imageUrl })`">
        <Main-nav :type="type"></Main-nav>
        <h1 :class="`template__hero-heading template__hero-heading--${type}`">{{ headingText }}</h1>
    </header>
    <main :class="`main main--${type}`">
        <section class="template__section section">
            <div class="section__inner section__inner--small">
                <slot></slot>
            </div>
        </section>
        <section class="template__section section">
            <div class="template__section-inner template__section-inner--center section__inner section__inner--small">
                <blockquote class="template__blockquote" v-if="quoteText && quoteText.length > 0" v-html="quoteText.join('<br>')">
                </blockquote>
                <br v-if="quoteText && quoteText.length > 0 && (orangeBtnText || blueBtnText)">
                <br v-if="quoteText && quoteText.length > 0 && (orangeBtnText || blueBtnText)">
                <br v-if="quoteText && quoteText.length > 0 && (orangeBtnText || blueBtnText)">
                <br v-if="quoteText && quoteText.length > 0 && (orangeBtnText || blueBtnText)">
                <div class="template__btns" v-if="orangeBtnText || blueBtnText">
                    <a :href="orangeBtnLink" class="template__button btn btn--orange btn--standalone-large" v-if="orangeBtnText">{{ orangeBtnText }}</a>
                    <a :href="blueBtnLink" class="template__button btn btn--blue btn--standalone-large" v-if="blueBtnText">{{ blueBtnText }}</a>
                </div>
            </div>
        </section>
    </main>
    <Footer></Footer>
</template>
<script>
import MainNav from './../components/MainNav.vue'
import Footer from './../components/Footer.vue'

export default {
    components: { MainNav, Footer },
    props: {
        type: {
            type: String,
            default: 'cruiser',
        },
        headingText: String,
        imageUrl: String,
        quoteText: Object,
        orangeBtnText: String,
        orangeBtnLink: String,
        blueBtnText: String,
        blueBtnLink: String,
    }
}
</script>