<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.how-it-works') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.how-it-works') })" />
    </Head>
    <header :style="{display: 'flex', flexDirection: 'column', minHeight: '100vh'}">
        <Main-nav></Main-nav>
        <Content-hero
            :style="{flex: 1}"
            background-img-url="/assets/imgs/content-headers/for-firms.jpg"
            :title="$t('LABELS.how-it-works')"
            :sub-title="$t('LABELS.info-about-costa-cruises')"
        />
        <Content-under-hero :text="$t('TEXTS.content-how-it-works-under-hero')" />
    </header>
    <main class="main content-pages__main content-pages__main--how-it-works">
        <ContentHalves
            :title="$t('LABELS.cabins')"
            :text="[
                $t('TEXTS.about-cabin-text-1'),
                $t('TEXTS.about-cabin-text-2'),
                $t('TEXTS.about-cabin-text-3'),
                `${$t('TEXTS.about-cabin-text-4')}<br>
                ${$t('LABELS.inner-cabin')}<br>
                ${$t('LABELS.sea-view-cabin')}<br>
                ${$t('LABELS.terrace-cabin')}<br>
                ${$t('LABELS.apartman-cabin')}<br>
                ${$t('LABELS.luxury-apartman-cabin')}`
            ]"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            image-side="left"
            waves-location="bottom-right"
            bigger-btn
            widerText
        >
            <GridGallery variant="1" :imgsUrls="[
                'VE_Suite 1.jpg',
                'DI_Balcony 2 1.jpg',
                'VE_Bathroom 1.jpg',
                'SM_Suite 5 1.jpg',
                'DE_Balcony 2 2.jpg',
                'SM_Suite 4 1.jpg',
                'FI_Balcony 2 1.jpg',
                'FO_Outside Window 1.jpg',
                'DI_Suite 1.jpg'
            ].map(file => `/assets/imgs/content/${file}`)"></GridGallery>
        </ContentHalves>
        <ContentHalves
            :title="$t('LABELS.food')"
            :text="[
                $t('TEXTS.about-food-text-1'),
                $t('TEXTS.about-food-text-2'),
                $t('TEXTS.about-food-text-3'),
                $t('TEXTS.about-food-text-4'),
            ]"
            imageUrl="/assets/imgs/content/d8d5f60d-7956-4f68-9228-5e737d825597 1.jpg"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            bigger-btn
            image-side="right"
            waves-location="bottom-left"
            widerText
        />
        <h2 class="content-pages__heading-2">
            <b class="content-pages__bold">{{ $t('TEXTS.how-it-works-heading-1') }}</b><br>
            {{ $t('TEXTS.how-it-works-heading-2') }}
        </h2>
        <div class="features-dash__container features-dash__container--grid">
            <FeaturesDash
                :subHeading="$t('LABELS.accomodation-package')"
                :heading="$t('LABELS.my-cruise')"
                :features="[
                    $t('TEXTS.my-cruise-feature-1'),
                    $t('TEXTS.my-cruise-feature-2'),
                    $t('TEXTS.my-cruise-feature-3'),
                    $t('TEXTS.my-cruise-feature-4'),
                    $t('TEXTS.my-cruise-feature-5')
                ]"
                type="info"
                wavesLocation="bottom-left"
            ></FeaturesDash>
            <FeaturesDash
                :subHeading="$t('LABELS.accomodation-package')"
                :heading="$t('LABELS.all-inclusive')"
                :features="[
                    $t('TEXTS.all-inclusive-feature-1'),
                    $t('TEXTS.all-inclusive-feature-2'),
                    $t('TEXTS.all-inclusive-feature-3'),
                    $t('TEXTS.all-inclusive-feature-4'),
                    $t('TEXTS.all-inclusive-feature-5')
                ]"
                type="info"
            ></FeaturesDash>
            <FeaturesDash
                :subHeading="$t('LABELS.services-basic-price-includes')"
                :heading="$t('LABELS.basic-price-includes')"
                :features="[
                    $t('TEXTS.basic-price-includes-1'),
                    $t('TEXTS.basic-price-includes-2'),
                    $t('TEXTS.basic-price-includes-3'),
                    $t('TEXTS.basic-price-includes-4'),
                    $t('TEXTS.basic-price-includes-5'),
                    $t('TEXTS.basic-price-includes-6'),
                ]"
                :footerText="$t('TEXTS.basic-price-includes-footer')"
                type="includes"
            ></FeaturesDash>
            <FeaturesDash
                :subHeading="$t('LABELS.additional-services')"
                :heading="$t('LABELS.basic-price-excludes')"
                :features="[
                    $t('TEXTS.basic-price-excludes-1'),
                    $t('TEXTS.basic-price-excludes-2'),
                    $t('TEXTS.basic-price-excludes-3'),
                    $t('TEXTS.basic-price-excludes-4'),
                    $t('TEXTS.basic-price-excludes-5'),
                    $t('TEXTS.basic-price-excludes-6'),
                    $t('TEXTS.basic-price-excludes-7'),
                    $t('TEXTS.basic-price-excludes-8'),
                ]"
                :footerText="$t(
                    'TEXTS.basic-price-excludes-footer',
                    { email: `${$globalContact[TYPE][LANGUAGE].email}` }
                )"
                type="additional"
                wavesLocation="bottom-right"
            ></FeaturesDash>
            <FeaturesDash
                :subHeading="$t('LABELS.cclub-features')"
                :heading="$t('LABELS.cclub')"
                :features="[
                    $t('TEXTS.cclub-features-1'),
                    $t('TEXTS.cclub-features-2'),
                    $t('TEXTS.cclub-features-3'),
                    $t('TEXTS.cclub-features-4'),
                ]"
                :footerText="$t(
                    'TEXTS.cclub-features-footer',
                    { email: `${$globalContact[TYPE][LANGUAGE].email}` }
                )"
                type="info"
                size="fullwidth"
            ></FeaturesDash>
        </div>
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import GridGallery from '../../components/GridGallery';
import Footer from '../../components/Footer';
import FeaturesDash from '../../components/FeaturesDash.vue';
import { TYPE, LANGUAGE } from '@/env';

export default {
    components: {
    Head,
    MainNav,
    ContentHero,
    ContentUnderHero,
    Footer,
    ContentHalves,
    ContactBar,
    GridGallery,
    FeaturesDash
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`
            ]
        }
    }
}
</script>