<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>

    <header class="header-detail">
        <Main-nav type="yacht"></Main-nav>
    </header>
    <main class='main reservation'>
        <div class='reservation__inner'>
            <Notification :text='error.text?.[LANGUAGE]' v-show='error.show' @closed='closeNotification'
                          type='error'></Notification>
            <Reservation-nav :currentLevel='1' type='yacht' reservation-type='cabin'></Reservation-nav>
            <div class='reservation__content reservation__content--summary'>
                <h2 class='reservation__heading reservation__heading--yacht'>
                    {{ $t('TEXTS.how-many-cabins-to-reserve') }}
                </h2>
                <div class='yacht-reservation'>
                    <ReservationSideYacht :yacht-details='yachtDetails' :price='overallPrice'></ReservationSideYacht>
                    <div class='yacht-reservation__part yacht-reservation__part--overview'>
                        <ReservationUnitsCounter
                            :type='type'
                            :cruise_code='cruise_code'
                            :title="$t('TEXTS.fill-how-many-cabins-to-reserve')"
                            :counter-note="$t('LABELS.empty-cabins-count')"
                            :free-units='yachtDetails.freeUnits?.[type]'
                            :transports='yachtDetails.transports'
                            @units-changed='handleUnitsChanged'
                            @passengers-changed='handlePassengersChanged'
                            @error='showError'
                        ></ReservationUnitsCounter>
                    </div>
                    <div class='yacht-reservation__part yacht-reservation__part--submit'>
                        <div class='yacht-reservation__gdpr'>
                            <input type='checkbox' id='checkbox-1' v-model='gdpr'>
                            <label class='yacht-reservation__text yacht-reservation__text--small' for='checkbox-1'>
                                {{ $t('TEXTS.agree-with-gdpr') }} <a href="https://info.nautyy.cz/ochrana-soukromi/" target="_blank">{{ $t('TEXTS.GDPR') }}</a>
                            </label>
                        </div>
                        <button v-on:click='handleNextStep' v-bind:class='{"btn--disabled": !gdpr}' :disabled='!gdpr'
                                class='btn btn--orange btn-full-width yacht-reservation__submit-btn'>
                            {{ $t('LABELS.to-summary') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer type='yachtas'></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-reservation-info')"></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationSideYacht from '../../components/ReservationSideYacht';
import ReservationUnitsCounter from '../../components/ReservationUnitsCounter';
import ReservationNav from '../../components/ReservationNav.vue';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';
import Notification from '../../components/Notification.vue';

import { Head } from '@vueuse/head'

import { LANGUAGE } from '@/env'

export default {
    props: ['cruise_code'],
    data() {
        return {
            LANGUAGE: LANGUAGE,
            api: new Api(this.$t),
            type: 'cabin',
            gdpr: true,
            passengersValidation: {
                isValid: true,
                errorMessage: ''
            },
            bookedUnits: this.$route.query.amount || 1,
            yachtDetails: {},
            isLoading: false,
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: []
                }
            }
        };
    },
    methods: {
        async loadYachtDetail() {
            this.yachtDetails = await this.api.getYachtDetail(this.cruise_code);
        },
        handleNextStep() {
            const { isValid, errorMessage } = this.passengersValidation;

            if (isValid) {
                return this.$router.push(`/rezervace/${this.cruise_code}/shrnuti/?type=${this.type}&amount=${this.bookedUnits}`);
            }
            this.showError({
                text: {
                    cs: [errorMessage],
                    sk: [errorMessage]
                }
            });
        },
        handleUnitsChanged(num) {
            this.bookedUnits = num;
        },
        handlePassengersChanged(validation) {
            this.passengersValidation = validation;
            this.closeNotification();
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: []
            };
        },
        showError(err) {
            window.scrollTo(0, 0);

            this.error.show = true;
            this.error.text = err.text;
        }
    },
    computed: {
        overallPrice() {
            const amount = this.bookedUnits;
            const price = { ...(this.yachtDetails.prices ? this.yachtDetails.prices[this.type]?.[this.$store.currency] : {}) };

            price.amount_decimal *= amount;
            price.amount_int *= amount;

            return price;
        }
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationUnitsCounter,
        ReservationSideYacht,
        Loading,
        Notification,
        Head
    },
    async mounted() {
        this.isLoading = true;
        await this.loadYachtDetail();
        this.isLoading = false;
    }
};
</script>