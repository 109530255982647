<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <header class="header-detail" ref="header-detail">
        <Main-nav :type="type"></Main-nav>
    </header>
    <main :class="`main main--${type} login-form__container`">
        <Notification :text="error.text[LANGUAGE]" v-show="error.show" :initConfig="{small: true}" @closed="closeNotification"></Notification>
        <form class="login-form" @submit.prevent="createAccount">
            <h1 :class="`login-form__heading login-form__heading--${type}`">{{ $t('LABELS.account-creation') }}</h1>
            <div class="login-form__input-container">
                <label for="email" class="login-form__label">{{ $t('LABELS.email') }}</label>
                <input type="email" name="email" id="email" class="login-form__input" required v-model="email">
            </div>
            <div class="login-form__input-container">
                <label for="password" class="login-form__label">{{ $t('LABELS.password') }}</label>
                <input type="password" name="password" id="password" class="login-form__input" required v-model="password">
            </div>
            <div class="login-form__input-container">
                <label for="passwordAgain" class="login-form__label">{{ $t('LABELS.password-again') }}</label>
                <input type="password" name="password" id="passwordAgain" class="login-form__input" required v-model="passwordAgain">
            </div>
            <div class="login-form__input-container login-form__input-container--checkbox">
                <input type="checkbox" name="gdpr" id="gdpr" class="login-form__checkbox" required v-model="gdprAllowed"><label for="gdpr">{{ $t('TEXTS.agree-with') }} <a href="https://info.nautyy.cz/ochrana-soukromi/" target="_blank" class="login-form__link login-form__link--hidden">{{ $t('LABELS.gdpr').toLowerCase() }}</a></label>
            </div>
            <input type="submit" value="Vytvořit účet" class="login-form__submit-btn btn btn--full-width btn--orange">
            <div class="login-form__links">
                <router-link to="/prihlaseni/" class="login-form__link">{{ $t('LABELS.login') }}</router-link>
                <router-link to="/zapomenute-heslo/" class="login-form__link">{{ $t('LABELS.fogotten-password-q') }}</router-link>
            </div>
        </form>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.registering')"></Loading>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import { Api } from '../modules/Api'
import Notification from '../components/Notification.vue'
import Loading from '../components/Loading.vue';

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    components: {
        Footer,
        MainNav,
        Notification,
        Loading,
        Head
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            email: '',
            password: '',
            passwordAgain: '',
            gdprAllowed: false,
            error: {
                show: false,
                text: []
            },
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser'
        }
    },
    methods: {
        async createAccount() {
            this.closeNotification()

            if (this.email === '' || this.password === '' || this.passwordAgain === '') {
                window.scrollTo(0,0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')]
                }
                return false
            } else if (this.password !== this.passwordAgain) {
                window.scrollTo(0,0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.different-passwords')],
                    sk: [this.$t('ERRORS.different-passwords')]
                }
            } else if (!this.gdprAllowed) {
                window.scrollTo(0,0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.not-accepted-gdpr')],
                    sk: [this.$t('ERRORS.not-accepted-gdpr')]
                }
            }

            this.isLoading = true;
            const status = await this.api.registerAccount(this.email, this.password);
            this.isLoading = false;
            if (status) {
                this.$router.push('/prihlaseni/');
                return true;
            }

            window.scrollTo(0,0);
            this.error.show = true;
            this.error.text = {
                cs: [this.$t('ERRORS.generic-ups')],
                sk: [this.$t('ERRORS.generic-ups')]
            }
        },
        closeNotification() {
            this.error.show = false
            this.error.text = {
                cs: [],
                sk: []
            }
        }
    },
    async mounted() {
        if (this.$store.user.isLogged) {
            this.$router.push('/uzivatel/');
            return;
        }
    },
    computed: {
        
    },
    watch: {
        
    }
};
</script>