<template>
    <ShowcaseItemWrapper :type='type' :url='`/detail/${code}/${urlSearch}`' item-type='horizontal'
                         :force-html-link='forceHtmlLink'>
        <div class='wide-showcase-item__badges'>
            <div class='showcase-item__badge'>
                <span class='showcase-item__badge-txt'><strong>{{ duration }}</strong> {{ daysInflected(duration)
                    }}</span>
            </div>
            <!-- <div class="showcase-item__badge" v-if="transportIncluded">
                <svg class="showcase-item__badge-icon">
                    <use href="/assets/imgs/icons/sprite.svg#car"></use>
                </svg>
                <span class="showcase-item__badge-txt">{{ $t('LABELS.includes-transport) }}</span>
            </div> -->
            <div class='showcase-item__badge' v-if="type === 'cruiser'">
                <svg class='showcase-item__badge-icon'>
                    <use href='/assets/imgs/icons/sprite.svg#cruiser'></use>
                </svg>
                <span class='showcase-item__badge-txt'>{{ cruiserName }}</span>
            </div>
            <div class='showcase-item__badge showcase-item__badge--orange' v-else-if="type === 'yacht'">
                <svg class='showcase-item__badge-icon showcase-item__badge-icon--yacht'>
                    <use href='/assets/imgs/icons/sprite.svg#yacht'></use>
                </svg>
                <span class='showcase-item__badge-txt'>{{ yachtType }}</span>
            </div>
            <div class='showcase-item__badge showcase-item__badge--black' v-if='car'>
                <svg class='showcase-item__badge-icon showcase-item__badge-icon--yacht showcase-item__badge-icon--car'>
                    <use href='/assets/imgs/icons/sprite.svg#car'></use>
                </svg>
                <span class='showcase-item__badge-txt'>
                    {{ car }}
                </span>
            </div>
            <div class='showcase-item__badge showcase-item__badge--black' v-if='plane'>
                <svg
                    class='showcase-item__badge-icon showcase-item__badge-icon--yacht showcase-item__badge-icon--plane'>
                    <use href='/assets/imgs/icons/sprite.svg#plane'></use>
                </svg>
                <span class='showcase-item__badge-txt'>
                    {{ plane }}
                </span>
            </div>
        </div>
        <header class='wide-showcase-item__img-container'>
            <a
                v-if="type === 'cruiser' && forceHtmlLink"
                :href='`/detail/${code}/${urlSearch}`'
            ><img :src='imageUrl' class='wide-showcase-item__img'></a>
            <router-link
                v-else-if="type === 'cruiser'"
                :to='`/detail/${code}/${urlSearch}`'
            ><img :src='imageUrl' class='wide-showcase-item__img'></router-link>
            <img v-else :src='imageUrl' class='wide-showcase-item__img'>

            <div class='showcase-item__badges showcase-item__badges--lower'>
                <div :class='`wide-showcase-item__label wide-showcase-item__label--${ theme }`' v-if="type === 'yacht'">
                    <svg :class='`wide-showcase-item__label-icon wide-showcase-item__label-icon--${ theme }`'>
                        <use :href='`/assets/imgs/icons/sprite.svg#${ theme }`'></use>
                    </svg>
                    <span class='wide-showcase-item__label-txt'>{{ themeName }}</span>
                </div>
                <div class='wide-showcase-item__label wide-showcase-item__label--notice' v-if="promoName">
                    <span class='wide-showcase-item__label-txt'>{{ promoName }}</span>
                </div>
                <div class='wide-showcase-item__label wide-showcase-item__label--notice'
                     v-if="type === 'yacht' && noticeText">
                    <svg class='wide-showcase-item__label-icon wide-showcase-item__label-icon--notice'>
                        <use href='/assets/imgs/icons/sprite.svg#notice'></use>
                    </svg>
                    <span class='wide-showcase-item__label-txt'>{{ noticeText }}</span>
                </div>
            </div>
        </header>
        <section class='wide-showcase-item__section'>
            <h3 class='wide-showcase-item__heading' v-if="type === 'cruiser'"><span
                class='wide-showcase-item__sub-heading'>{{ destination }}</span>{{ $t('LABELS.departure-from') }}
                {{ departureCity }}</h3>
            <h3 class='showcase-item__heading showcase-item__heading--no-mb' v-else>
                <span class='showcase-item__sub-heading'>
                    <strong>{{ country }}</strong> - {{ destination }}
                </span>
                {{ yachtName }}
            </h3>
            <p class='wide-showcase-item__schedule' v-html='itineraryMarkup'></p>
        </section>
        <section class='wide-showcase-item__section'>
            <h4 class='wide-showcase-item__mini-heading'>{{ $t('LABELS.departure-and-return') }}</h4>
            <div class='wide-showcase-item__departures'>
                <div class='showcase-item__departure'>
                    <span class='showcase-item__departure-label'>
                        {{ $t('LABELS.departure').toLowerCase() }}
                    </span>
                    <span class='showcase-item__departure-date'>
                        {{ departureDateText }} <br />
                        {{ departureName }}
                    </span>
                </div>
                <div class='showcase-item__departure'>
                    <span class='showcase-item__departure-label'>
                        {{ $t('LABELS.arrival').toLowerCase() }}
                    </span>
                    <span class='showcase-item__departure-date'>
                        {{ arrivalDateText }} <br />
                        {{ arrivalName}}
                    </span>
                </div>
            </div>
        </section>
        <section class='wide-showcase-item__section'>
            <p class='wide-showcase-item__price-container'>
                <span class='wide-showcase-item__price-label'
                      v-if="isSinglePrice && type === 'cruiser'">{{ $t('TEXTS.price-for-single-with')
                    }}<br>{{ $t('TEXTS.occupied-two-seats') }}</span>
                <span class='wide-showcase-item__price-label'
                      v-else-if="isSinglePrice && type === 'yacht'">{{ $t('LABELS.price-for-single') }}</span>
                <span class='wide-showcase-item__price-label' v-else>{{ defaultPriceLabelTranslated }}</span>
                <span
                    class='wide-showcase-item__price'
                    :class="{ 'wide-showcase-item__price--discounted': priceFull !== undefined && priceFull?.amount_decimal != price?.amount_decimal } "
                >{{ priceText }}</span>
                <sup class='wide-showcase-item__price-full' v-if='priceFull !== undefined && priceFull?.amount_decimal != price?.amount_decimal'>{{ fullPriceText
                    }}</sup>
            </p>
            <p class='wide-showcase-item__second-price-text' v-if='secondPrice'>{{ secondPriceLabel }}
                {{ secondPriceText }}</p>
            <p v-if="type === 'yacht' && !this.freeUnits?.single" class="showcase-item__sold-out-note">{{ $t('LABELS.everything-sold-out') }}</p>
            <div v-else class='wide-showcase-item__btns'>
                <router-link
                    :to='`/rezervace/${code}/kajuta-a-ubytovaci-balicek/${urlSearch}`'
                    class='wide-showcase-item__btn btn btn--orange'
                    v-if="type === 'cruiser'"
                >{{ $t('LABELS.choose-cabin') }}
                </router-link>
                <a
                    v-if='forceHtmlLink'
                    :href='`/detail/${code}/${urlSearch}`'
                    class='wide-showcase-item__btn btn'
                    :class="{
                        'btn--blue': type === 'cruiser',
                        'btn--orange': type !== 'cruiser'
                    }"
                >{{ $t('LABELS.more-about-cruise') }}</a>
                <router-link
                    v-else
                    :to='`/detail/${code}/${urlSearch}`'
                    class='wide-showcase-item__btn btn'
                    :class="{
                        'btn--blue': type === 'cruiser',
                        'btn--orange': type !== 'cruiser'
                    }"
                >{{ $t('LABELS.more-about-cruise') }}
                </router-link>
                <span class='btn btn--grey-bordered' v-if='this.type === "yacht"'>
                    {{ upperCaseFirst(inflect('left', this.freeUnits?.single)) }}
                    <strong>{{ this.freeUnits?.single }}</strong>
                    {{ inflect('freeMid', this.freeUnits?.single) }}
                    {{ inflect('place', this.freeUnits?.single) }}
                </span>
            </div>
        </section>
        <div class='wide-showcase-item__type-badge-container' v-if="type === 'cruiser'">
            <svg class='wide-showcase-item__type-badge'>
                <use href='/assets/imgs/icons/sprite.svg#cruiser'></use>
            </svg>
            <p class='wide-showcase-item__type-badge-label'>{{ cruiserName }}</p>
        </div>
    </ShowcaseItemWrapper>
</template>

<script>
import { Dictionary } from '../modules/Dictionary';
import formatDateUtil from '../utilities/formatDate';
import formatPriceUtil from '../utilities/formatPrice';
import ShowcaseItemWrapper from './ShowcaseItemWrapper.vue';
import { upperCaseFirst as upperCaseFirstUtil } from 'upper-case-first';

import { LANGUAGE } from '@/env';

const dict = new Dictionary;

export default {
    components: {
        ShowcaseItemWrapper
    },
    props: {
        freeUnits: String,
        type: String,
        duration: Number,
        transportIncluded: Boolean,
        imageUrl: String,
        departureCity: String,
        destination: String,
        itinerary: Array,
        departureDate: String,
        departureName: String,
        arrivalDate: String,
        arrivalName: String,
        price: Object,
        priceFull: Object,
        secondPrice: Object,
        secondPriceLabel: String,
        code: String,
        isSinglePrice: Boolean,
        cruiserName: String,
        yachtName: String,
        yachtType: String,
        theme: String,
        themeName: String,
        defaultPriceLabel: String,
        car: String,
        plane: String,
        forceHtmlLink: Boolean,
        urlSuffix: String,
        noticeText: String,
        country: String,
        promoName: String,
    },
    methods: {
        upperCaseFirst(word) {
            return upperCaseFirstUtil(word)
        },
        daysInflected(days_num) {
            return dict.getWord('days', days_num, LANGUAGE);
        },
        formatDate(date) {
            return formatDateUtil(date);
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        inflect(word, num) {
            return dict.getWord(word, num, LANGUAGE);
        }
    },
    computed: {
        urlSearch() {
            if (this.urlSuffix) {
                return `?${this.urlSuffix}`;
            }
            return '';
        },
        defaultPriceLabelTranslated() {
            return this.defaultPriceLabel ? this.defaultPriceLabel : this.$t('LABELS.price-for-two-people');
        },
        itineraryMarkup() {
            if (this.type === 'yacht') {
                return ' ';
            }

            const itinerary = this.itinerary.map((city, i) => {
                if (i === 0) {
                    city = `${city} (${this.$t('LABELS.departure')}) `;
                } else {
                    if (i + 1 === this.itinerary.length) {
                        city = `${city} (${this.$t('LABELS.arrival')})`;
                    }

                    city = `→ ${city}</span>`;
                }

                return city;
            });

            return itinerary.join(' <span class="nowrap">');
        },
        departureDateText() {
            return this.formatDate(this.departureDate);
        },
        arrivalDateText() {
            return this.formatDate(this.arrivalDate);
        },
        priceText() {
            return `${this.formatPrice(this.price.amount_decimal)} ${this.price.currency}`;
        },
        fullPriceText() {
            return `${this.formatPrice(this.priceFull.amount_decimal)} ${this.priceFull.currency}`;
        },
        secondPriceText() {
            if (this.secondPrice) {
                return `${this.formatPrice(this.secondPrice.amount_decimal)} ${this.secondPrice.currency}`;
            }
            return '';
        }
    }
};
</script>