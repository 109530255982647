<template>
    <header class="sticky-header-cruise__header" v-show="showStickyHeader">
        <div class="sticky-header-cruise__header-part">
            <h3 class="sticky-header-cruise__header-heading">{{ $t('LABELS.sail-date') }}</h3>
            <p class="sticky-header-cruise__header-text"><span class="sticky-header-cruise__arrow">→</span>{{ formatDate(details.departure_date) }}</p>
            <p class="sticky-header-cruise__header-text"><span class="sticky-header-cruise__arrow">←</span>{{ formatDate(details.arrival_date) }}</p>
        </div>
        <div class="sticky-header-cruise__header-part">
            <h3 class="sticky-header-cruise__header-heading">{{ $t('LABELS.total-time') }}</h3>
            <p class="sticky-header-cruise__header-text">{{ details.days }} {{ daysInflected(details.days) }}</p>
        </div>
        <!-- <div class="sticky-header-cruise__header-part">
            <h3 class="sticky-header-cruise__header-heading">{{ $t('LABELS.transport') }}</h3>
            <p class="sticky-header-cruise__header-text" v-if="details.transport_included">{{ $t('TEXTS.transport-posibility') }}<wbr> {{ $t('TEXTS.to-port-and-back') }}</p>
            <p class="sticky-header-cruise__header-text" v-else>{{ $t('TEXTS.transport-to-port') }}<wbr> {{ $t('TEXTS.and-back-not-managed') }}</p>
        </div> -->
        <div class="sticky-header-cruise__header-part sticky-header-cruise__header-part--iconed">
            <svg class="sticky-header-cruise__header-icon sticky-header-cruise__header-icon--cruiser">
                <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
            </svg>
            <h3 class="sticky-header-cruise__header-heading">{{ $t('LABELS.costa-cruises') }}</h3>
            <p class="sticky-header-cruise__header-text">{{ details.ship_name?.[LANGUAGE] }}</p>
        </div>
        <div class="sticky-header-cruise__header-part">
            <p class="sticky-header-cruise__price-box">
                <span class="sticky-header-cruise__price-label">{{ $t('TEXTS.price-for-single-with') }}<br>{{ $t('TEXTS.occupied-two-seats') }}</span>
                <span
                    class="sticky-header-cruise__price"
                >{{ priceText }}</span>
            </p>
            <p class="sticky-header-cruise__second-price-text">{{ $t('TEXTS.price-for-two-from') }} {{ secondPriceText }}</p>
        </div>
        <div class="sticky-header-cruise__header-part sticky-header-cruise__header-part--v-center">
            <router-link :to="`/rezervace/${details.cruise_code}/kajuta-a-ubytovaci-balicek/${urlCrewSearch}`" class="sticky-header-cruise__btn btn btn--orange">{{ $t('LABELS.choose-cabin') }}</router-link>
        </div>
    </header>
</template>
<script>

import formatDateUtil from '../utilities/formatDate';
import formatPriceUtil from '../utilities/formatPrice'
import findRefsComponent from '../utilities/findRefsComponent'
import { Dictionary } from '../modules/Dictionary'

import { LANGUAGE } from '@/env'

const dict = new Dictionary;

export default {
    props: {
        details: Object,
        urlCrewSearch: String
    },
    data() {
        return {
            showStickyHeader: false,
            LANGUAGE: LANGUAGE,
        }
    },
    methods: {
        daysInflected(days_num) {
            return dict.getWord('days', days_num, LANGUAGE);
        },
        formatDate(date) {
            return formatDateUtil(date)
        },
        formatPrice(price) {
            return formatPriceUtil(price)
        },
    },
    computed: {
        priceText() {
            console.log(this.details);
            return `${this.formatPrice(this.details?.singleForTwoPrice?.[this.$store.currency].amount_decimal)} ${this.details?.singleForTwoPrice?.[this.$store.currency].currency}`;
        },
        secondPriceText() {
            console.log(this.details);
            return `${this.formatPrice(this.details?.priceDouble?.[this.$store.currency].amount_decimal)} ${this.details?.priceDouble?.[this.$store.currency].currency}`
        },
        cruisePriceText() {
            return `${this.formatPrice(this.details.prices ? this.details.prices.single[this.$store.currency].amount_decimal : undefined)} ${this.details.prices ? this.details.prices.single[this.$store.currency].currency : undefined}`;
        },
    },
    mounted() {
        document.addEventListener('scroll', () => {
            const triggerRefsComponent = findRefsComponent(this, 'sticky-header-trigger');
            const offsetRefsComponent = findRefsComponent(this, 'header-detail');

            if (triggerRefsComponent && offsetRefsComponent) {
                const triggerHeight = triggerRefsComponent.$refs['sticky-header-trigger'].scrollHeight;
                const menuHeight = offsetRefsComponent.$refs['header-detail'].scrollHeight;
                this.showStickyHeader = window.scrollY > triggerHeight + menuHeight + 55;
            }
        })
    }
}
</script>