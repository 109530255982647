<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>

    <header class='header-search'>
        <div class='header-search__background header-search__background--yacht'>
            <video
                class='header-search__background-video'
                ref='video'
                src='/assets/videos/yachts.mov'
                poster='/assets/imgs/posters/yacht-overview.jpg'
                muted autoplay loop
            ></video>
        </div>
        <Main-nav :fixed-allowed='true' type='yacht'></Main-nav>
        <div class='main-search__container'>
            <p class='main-search__heading-upper'>{{ $t('TEXTS.yacht-desktop-search-note-1') }}
                <b>{{ $t('TEXTS.yacht-desktop-search-note-2') }}</b> {{ $t('TEXTS.yacht-desktop-search-note-3') }}</p>
            <section class='main-search'>
                <header class='main-search__header'>
                    <h1 class='main-search__header-heading main-search__header-heading--longer'>
                        {{ $t('LABELS.cruises-on-yachts') }}</h1>
                    <h1 class='main-search__header-heading main-search__header-heading--shorter main-search__header-heading--yacht'>
                        {{ $t('LABELS.experience-yacht') }}</h1>
                </header>
                <MainSearch />
            </section>
            <Banner
                class='header-search__banner'
                :type='banner.desktop.type'
                :type-mobile='banner.mobile.type'
                :source='banner.desktop.source'
                :source-mobile='banner.mobile.source'
                :url='banner.url'
            >
            </Banner>
        </div>
    </header>
    <main class='main main--yacht'>
        <section class='section' v-if='aboutYachtingDashes.length'>
            <div class='section__inner section__inner--medium'>
                <h2 class='section__heading section__heading--yacht'>{{ $t('LABELS.info-about-yachting') }}</h2>
                <div class='gallery-grid'>
                    <MultitypeDashes
                        :dashes='aboutYachtingDashes'
                        :with-lightbox='true'
                    ></MultitypeDashes>
                </div>
            </div>
        </section>
        <section class='section section--no-mt'>
            <div
                class='section__inner section__inner--medium section__inner--white section__inner--pb section__inner--pt'>
                <h2 class='section__heading section__heading--yacht'>{{ $t('LABELS.how-it-works') }}</h2>
                <NumberedDashes :dashes='howItWorksDashes'></NumberedDashes>
            </div>
        </section>
        <section class='section' v-if='gallery?.length > 0'>
            <div class='section__inner section__inner--medium'>
                <Gallery
                    class='content-page__gallery'
                    :images='gallery'
                    :type='TYPE'
                    :reset='galleryResetCounter'
                    :loop='true'
                    :delay='3000'
                ></Gallery>
            </div>
        </section>
        <section class='section'>
            <div class='section__inner section__inner--medium'>
                <h2 class='section__heading section__heading--yacht'>{{ $t('LABELS.where-we-sailing-this-year') }}</h2>
                <div class='simple-box__container'>
                    <a
                        v-for='country in countriesWithCount'
                        :key='country.code'
                        :href='`/vyhledat/#countryCode=${country.code}`'
                        class='simple-box'
                    >
                            <h3 class='simple-box__heading'>{{ country.name[LANGUAGE] }}</h3>
                            <p class='simple-box__text'>{{ country.count }} {{ inflectCruises(country.count)
                                }}</p>
                    </a>
                </div>
            </div>
        </section>
        <section class='section' v-if='reviews.length > 0'>
            <div class='section__inner section__inner--medium'>
                <h2 class='section__heading section__heading--yacht'>{{ $t('LABELS.review-talking') }}</h2>
                <Reviews :reviews='reviews'></Reviews>
            </div>
        </section>
    </main>
    <Footer type='yachtas'></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-page')"></Loading>
</template>

<script>
import ClickOutside from '../../modules/vendor/vue-click-outside';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Dictionary } from '../../modules/Dictionary';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';
import NumberedDashes from '../../components/NumberedDashes';
import Reviews from '../../components/Reviews';
import Gallery from '../../components/Gallery';
import MultitypeDashes from '@/components/MultitypeDashes';
import MainSearch from '@/components/MainSearch';
import * as _ from "lodash"

import { Head } from '@vueuse/head';

import { LANGUAGE, TYPE } from '@/env';

const dict = new Dictionary;

export default {
    components: {
        MultitypeDashes,
        Footer,
        MainNav,
        Loading,
        Banner,
        Head,
        NumberedDashes,
        Reviews,
        Gallery,
        MainSearch,
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            isLoading: false,
            adultsNum: 2,
            childrenNum: 0,
            country: this.$t('LABELS.anywhere'),
            banner: { url: '', mobile: {}, desktop: {} },
            howItWorksDashes: [],
            countriesWithCount: [],
            reviews: [],
            gallery: [],
            galleryResetCounter: 0,
            aboutYachtingDashes: [],
        };
    },
    computed: {
        adultsLimit() {
            return 4 - this.childrenNum;
        },
        childrenLimit() {
            return 4 - this.adultsNum;
        },
        adultsInflected() {
            return dict.getWord('adults', this.adultsNum, LANGUAGE);
        },
        childrenInflected() {
            return dict.getWord('children', this.childrenNum, LANGUAGE);
        },
    },
    methods: {
        inflectCruises(num) {
            return dict.getWord('cruises', num, LANGUAGE);
        },
        async loadBanner() {
            this.banner = await this.api.getBanner(TYPE, LANGUAGE, 'homepage') || this.banner;
        },
        async loadHowItWorksDashes() {
            this.howItWorksDashes = await this.api.getYachtasHowItWorks();
        },
        async loadCountriesWithCruisesCount() {
            const countriesWithCount = await this.api.getYachtCountries()
            this.countriesWithCount = _.filter(countriesWithCount, country => {
                return country.count > 0 && country.name[LANGUAGE] !== "Kamkoliv"
            })
        },
        async loadReviews() {
            this.reviews = await this.api.getReviews();
        },
        async loadGallery() {
            this.gallery = await this.api.getYachtasMainPageGallery();
        },
        async loadAboutYachtingDashes() {
            this.aboutYachtingDashes = await this.api.getAboutYachtingDashesInfo();
        },
    },
    async mounted() {
        const { searchParams } = new URL(window.location.href);

        const orderId = searchParams.get('orderid');
        const orderStatus = searchParams.get('status');

        if (orderId && orderStatus) {
            return this.$router.push(`/order-status/${orderId}?status=${orderStatus}`);
        }

        this.isLoading = true;
        try {
            await Promise.all([
                this.loadHowItWorksDashes(),
                this.loadCountriesWithCruisesCount(),
                this.loadReviews(),
                this.loadGallery(),
                this.loadAboutYachtingDashes(),
                this.loadBanner()
            ]);
        } catch {
            this.isLoading = false;
        }
        this.isLoading = false;
        this.galleryResetCounter++;
    },
    directives: {
        ClickOutside
    }
};
</script>