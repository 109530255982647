export default itineraryArr => {
    const itinerary = itineraryArr.map((city, i) => {
        if (i === 0) {
            city = `${city} (Odjezd) `
        } else {
            if (i + 1 === itineraryArr.length) {
                city = `${city} (Návrat)`
            }

            city = `→ ${city}</span>`
        }

        return city
    })

    return itinerary.join(' <span class="nowrap">');
}