<template>
    <div class="loading" :class="{ 'loading--show': isLoading }">
        <svg :class="`loading__icon loading__icon--${type}`">
            <use :href="loadingIconUrl"></use>
        </svg>
        <div class="loading__bar" v-if="showBar"><div :class="`loading__progress loading__progress--${type}`" :style="{width: `${progress}%`}"></div></div>
        <p class="loading__label">{{ label }}...</p>
    </div>
</template>
<script>
import { TYPE } from '@/env'

export default {
    props: [ 'isLoading', 'label', 'showBar', 'progress' ],
    data() {
        return {
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser',
        }
    },
    computed: {
        loadingIconUrl() {
            if (TYPE === 'yachtas') {
                return '/assets/imgs/icons/sprite.svg#yacht';
            } else if (TYPE === 'cruises') {
                return '/assets/imgs/icons/sprite.svg#cruiser';
            }

            return '';
        }
    }
}
</script>