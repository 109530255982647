<template>
    <div :class="`content-halves content-halves--img-${imageSide}`">
        <div class="content-halves__part content-halves__part--img" v-if="!this.$slots.default">
            <img :src="imageUrl" class="content-halves__img">
            <img v-if="wavesLocation" src="/assets/imgs/waves.png" :class="`content-halves__waves content-halves__waves--${wavesLocation}`">
        </div>
        <div class="content-halves__part content-halves__part--img" v-else>
            <slot></slot>
            <img v-if="wavesLocation" src="/assets/imgs/waves.png" :class="`content-halves__waves content-halves__waves--${wavesLocation}`">
        </div>
        <div class="content-halves__part content-halves__part--text" :class="{'content-halves__part--text-wider': widerText}">
            <h3 class="content-halves__title">{{title}}</h3>
            <p class="content-halves__text" v-if="typeof text === 'string'" v-html="text"></p>
            <p class="content-halves__text" v-else v-for="t, x in text" v-html="t" :key="x"></p>
            <a :class="`btn btn--standalone-${biggerBtn ? 'large' : 'small'} btn--orange content-halves__btn`" :href="buttonUrl" v-if="buttonText">
                {{buttonText}}
            </a>
            <p class="content-halves__note" v-if="noteText">{{ noteText }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        text: [String, Array],
        buttonText: String,
        buttonUrl: String,
        imageUrl: String,
        imageSide: String,
        wavesLocation: String,
        biggerBtn: Boolean,
        widerText: Boolean,
        noteText: String
    }
}
</script>