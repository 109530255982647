<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.cruise-on-yacht') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.cruise-on-yacht') })" />
    </Head>
    <header>
        <Main-nav></Main-nav>
    </header>
    <main class="main cruises-yacht-content">
        <div class="cruises-yacht-content__half cruises-yacht-content__half--content">
            <div class="cruises-yacht-content__half-inner cruises-yacht-content__half-inner--left">
                <h1 class="cruises-yacht-content__heading">{{ $t('LABELS.cruise-on-yacht') }}</h1>
                <p class="cruises-yacht-content__text" v-html="$t('TEXTS.cruise-on-yacht-text')"></p>
                <a class="btn btn--orange btn--standalone cruises-yacht-content__btn" :href="$globalContact.yachtas[LANGUAGE].website">{{ $t('LABELS.want-know-more') }}</a>
            </div>
        </div>
        <div class="cruises-yacht-content__half cruises-yacht-content__half--video">
            <NewVideoYT type="yachtas" yt-url="https://www.youtube-nocookie.com/embed/HFVvKE4RaR4" preview-url="/assets/imgs/content/diakopes-istioploia-ellada_0 1.jpg" class="cruises-yacht-content__video"></NewVideoYT>
        </div>
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import NewVideoYT from '../../components/NewVideoYT';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';
import { LANGUAGE } from '@/env';

export default {
    data() {
        return {
            LANGUAGE,
        }
    },
    components: {
        Head,
        MainNav,
        Footer,
        ContactBar,
        NewVideoYT
    }
}
</script>