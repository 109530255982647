<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.for-groups') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.for-groups') })" />
    </Head>
    <header :style="{display: 'flex', flexDirection: 'column', minHeight: '100vh'}">
        <Main-nav></Main-nav>
        <Content-hero
            :style="{flex: 1}"
            background-img-url="/assets/imgs/content-headers/for-groups.jpg"
            :title="$t('LABELS.for-groups')"
            :sub-title="$t('TEXTS.how-to-order-group-cruise')"
        />
        <Content-under-hero :text="$t('TEXTS.content-for-groups-under-hero')" />
    </header>
    <main class="main content-pages__main content-pages__main--for-groups">
        <Content-halves
            :title="$t('LABELS.individual-price')"
            :text="$t('TEXTS.individual-price-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/individual-price-2.jpg"
            image-side="left"
            waves-location="bottom-right"
        />
        <Content-halves
            :title="$t('LABELS.custom-transport')"
            :text="$t('TEXTS.custom-transport-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/custom-transport-2.jpg"
            image-side="right"
        />
        <Content-halves
            :title="$t('LABELS.groups-from-8-people')"
            :text="$t('TEXTS.group-of-friends-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/group-of-friends.jpg"
            image-side="left"
        />
        <Content-halves
            :title="$t('LABELS.dream-program')"
            :text="$t('TEXTS.dream-program-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/dream-program-2.jpg"
            image-side="right"
            waves-location="top-left"
        />
        <Content-halves
        :title="$t('LABELS.delegate-services')"
            :text="$t('TEXTS.delegate-services-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/delegate-services-2.jpg"
            image-side="left"
        />
        <Content-halves
            :title="$t('LABELS.port-excursions')"
            :text="$t('TEXTS.port-excursions-text')"
            :button-text="$t('LABELS.order-group-cruise')"
            button-url="/kontakt/"
            image-url="/assets/imgs/content/port-excursions.jpg"
            image-side="right"
            waves-location="bottom-left"
        />
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContentHalves,
        ContactBar
    }
}
</script>