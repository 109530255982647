<template>
    <div class="js-tooltip tooltip" :class="{'tooltip--triangle-in': triangleIn}">
         {{ text }}
         <svg class="tooltip__triangle-icon">
             <use href="/assets/imgs/icons/sprite.svg#tooltip-triangle"></use>
         </svg>
    </div>
</template>

<script>
export default {
    props: ['text', 'triangleIn'],
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        const tooltips = Array.from(document.querySelectorAll('.js-tooltip'));
        tooltips.forEach(tooltip => tooltip.parentElement.classList.add('tooltip__container'))
    }
}
</script>