<template>
    <p class='history-item__price-note history-item__price-note--deposit'>{{ reservationInfoBox }}</p>
    <slot></slot>
    <div class='history-item__content'>
        <div class='history-item__content-col'>
            <h4 class='history-item__content-heading'>
                {{ type === 'cruiser' ? $t('LABELS.travellers') : $t('LABELS.responsible-person') }}</h4>
            <div
                class='history-item__crew-member'
                v-for='passenger, i in passengers'
                :key='i'
            >
                <h5 class='history-item__member-name'>{{ passenger.first_name }} {{ passenger.last_name }}</h5>
                <p class='history-item__member-info'>
                    {{ passenger.type === 'Adult' ? $t('LABELS.adult') : $t('LABELS.child') }},
                    {{ dials.sex ? dials.sex[passenger.sex] : '' }}, {{ $t('LABELS.nationality').toLowerCase() }}
                    {{ dials.nationality ? dials.nationality[passenger.nationality_code]?.toLowerCase() : '' }},
                    {{ $t('LABELS.date-of-birth').toLowerCase() }} {{ formatDate(passenger.birth_date)
                    }}{{ passenger.email ? `, ${passenger.email}` : ''
                    }}{{ passenger.phone ? `, ${passenger.phone}` : '' }}</p>
            </div>
        </div>
        <div class='history-item__content-col'>
            <h4 class='history-item__content-heading'>{{ $t('LABELS.files-to-download') }}</h4>
            <div class='history-item__downloads'>
                <a
                    target='_blank'
                    class='history-item__download-item'
                    v-for='file, i in files'
                    :key='i'
                    :href='`https://api.nautyy.cz/file/${file.id}`'
                >{{ file.name[LANGUAGE] }}</a>
            </div>
        </div>
        <div class='history-item__content-col history-item__content-col--card-pay' v-if='state !== "cancelled" && state !== "paidFull"'>
            <h4 class='history-item__content-heading'>{{ $t('TEXTS.pay-with-card') }}</h4>

            <div class='history-item__pay'>
                <a :href='ticketFull' v-if='state === "waitingForPayment"' class="btn btn--standalone btn--orange">{{ $t('LABELS.pay-everything') }}</a>
                <a :href='ticketDeposit' v-if='state === "waitingForPayment"' class="btn btn--standalone btn--orange">{{ $t('LABELS.pay-deposit-with-card') }}</a>

                <a :href='ticketRemaining' v-if='state === "paidDeposit"' class="btn btn--standalone btn--orange">{{ $t('LABELS.pay-remaining') }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import formatDateUtil from '../utilities/formatDate';
import formatPriceUtil from '../utilities/formatPrice';

import { LANGUAGE } from '@/env';

export default {
    data() {
        return {
            LANGUAGE: LANGUAGE
        };
    },
    props: {
        type: String,
        state: String,
        passengers: {
            default: []
        },
        files: {
            default: []
        },
        dials: {
            default: {}
        },
        paid: {
            default: {}
        },
        toBePaid: {
            default: {}
        },
        ticketRemaining: String,
        ticketDeposit: String,
        ticketFull: String,
        toBePaidDate: String
    },
    computed: {
        reservationInfoBox() {
            if (this.state === 'cancelled') {
                return this.$t('STATUSES.reservation-cancelled');
            } else if (this.state === 'cancelledPaidDeposit') {
                return this.$t('STATUSES.reservation-cancelled-deposit');
            } else if (this.state === 'paidDeposit') {
                return this.$t('STATUSES.reservation-paid-deposit', {
                    paidPrice: `${this.formatPrice(this.paid?.amount_decimal)} ${this.paid?.currency}`,
                    unpaidPrice: `${this.formatPrice(this.toBePaid?.amount_decimal)} ${this.toBePaid?.currency}`,
                    dueDate: this.formatDate(this.toBePaidDate)
                });
            } else if (this.state === 'waitingForPayment') {
                return this.$t('STATUSES.reservation-waiting-for-payment');
            } else {
                return this.$t('STATUSES.reservation-paid');
            }
        }
    },
    methods: {
        formatDate(date) {
            return formatDateUtil(date);
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        }
    }
};
</script>