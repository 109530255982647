export default price => {
    const rest = price % 1;
    const reverse_price = `${Math.floor(price)}`.split('').reverse();
    let final_price = [];

    for (let i = 0; i < reverse_price.length; i++) {
        final_price.push(reverse_price[i]);

        if ((i + 1) % 3 === 0) {
            final_price.push(' ');
        }
    }

    const final_string = `${final_price.reverse().join('')}${rest !== 0 ? `.${Math.floor(rest*100)}` : ''}`;

    return final_string;
}