<template>
    <div class="numbered-dash__container">
        <div
            class="numbered-dash"
            v-for="dash in dashes"
            :key="dash.order"
            :style="{
                backgroundImage: `url('${dash.backgroundUrl}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }"
        >
            <div class="numbered-dash__header">
                <div class="numbered-dash__number">{{ dash.order }}.</div>
                <h3 class="numbered-dash__heading">{{ dash.name[LANGUAGE] }}</h3>
            </div>
            <p class="numbered-dash__text" v-html="dash.text[LANGUAGE]"></p>
        </div>
    </div>
</template>
<script>
import { LANGUAGE } from '@/env'

export default {
    props: [ 'dashes' ],
    data() {
        return {
            LANGUAGE: LANGUAGE
        }
    }
}
</script>