<template>
    <Head>
        <title>{{ content?.metaTitle?.[LANGUAGE] }}</title>
        <meta name="description" :content="content?.metaDescription?.[LANGUAGE]">
        <meta property="og:title" :content="content?.metaTitle?.[LANGUAGE]">
        <meta property="og:description" :content="content?.metaDescription?.[LANGUAGE]">
        <meta property="og:image" :content="content?.heroImage?.url">
    </Head>

    <header class="header-detail">
        <Main-nav :type="oldType"></Main-nav>
    </header>
    <main class="main content-page">
        <header class="content-page__header"
            :style="`
                background-image: url(${content.heroImage?.url});
                background-size: cover;
                background-repeat: no-repeat;
            `"
        >
            <div class="content-page__header-text" :class="{
                'content-page__header-text--yachtas': type === 'yachtas',
                'content-page__header-text--cruises': type === 'cruises',
            }">
                <h1 class="content-page__heading-1">{{ content.heading?.[LANGUAGE] }}</h1>
                <p class="content-page__sub-heading-1">{{ content.subheading?.[LANGUAGE] }}</p>
            </div>
        </header>
        <section class="section">
            <div class="section__inner">
                <div class="content-page__wysiwyg" :class="{
                    'content-page__wysiwyg--yachtas': type === 'yachtas',
                    'content-page__wysiwyg--cruises': type === 'cruises',
                 }" v-html="content.content?.[LANGUAGE]"></div>
                <Gallery
                    v-if="content.gallery?.length > 0"
                    class="content-page__gallery"
                    :images="content.gallery"
                    :type="type"
                    :loop="content.gallery?.length > 3"
                    :delay="3000"
                    :reset="galleryResetCounter"
                ></Gallery>
            </div>
        </section>
        <section
            class="section content-page__more-section"
            v-if="
                (type === 'yachtas' && content.yachts?.length > 0)
                || (type === 'cruises' && content.cruises?.length > 0)
                || (content.moreUrl && content.moreLabel)
            "
            :class="{
                'section--light-orange': type === 'yachtas',
                'section--light-blue': type === 'cruises',
            }"
            >
            <div class="section__inner">
                <h2 class="content-page__heading-2" :class="{
                    'content-page__heading-2--yachtas': type === 'yachtas',
                    'content-page__heading-2--cruises': type === 'cruises',
                }"
                v-if="(type === 'yachtas' && content.yachts?.length > 0)
                || (type === 'cruises' && content.cruises?.length > 0)"
                >{{ content.listHeading }}</h2>
                <div class="content-page__more-list content-page__more-list--yachtas" v-if="type === 'yachtas' && content.yachts?.length > 0">
                    <showcase-item
                        class="content-page__more-dash--yacht"
                        v-for="yacht in content.yachts"
                        :key="yacht.code"
                        type="yacht"
                        :duration="yacht.duration"
                        :transport-included="yacht.transportIncluded"
                        :image-url="yacht.image"
                        :departure-city="yacht.sea?.[LANGUAGE]"
                        :destination="yacht.destinationName?.[LANGUAGE]"
                        :departure-date="yacht.departureDate"
                        :arrival-date="yacht.arrivalDate"
                        :price="yacht.prices?.single?.[$store.currency]"
                        :price-full="yacht.pricesFull?.single?.[$store.currency]"
                        :code="yacht.code"
                        :yacht-name="yacht.yachtName?.[LANGUAGE]"
                        :yacht-type="yacht.typeName?.[LANGUAGE]"
                        :theme="yacht.theme"
                        :theme-name="yacht.themeName?.[LANGUAGE]"
                        :default-price-label="$t('LABELS.price-first-person')"
                        :notice-text="yacht.label?.[LANGUAGE]"
                    ></showcase-item>
                </div>
                <div class="content-page__more-list content-page__more-list--cruises" v-if="type === 'cruises' && content.cruises?.length > 0">
                    <showcase-item-wide
                        class="content-page__more-dash--cruise content-page__more-dash--wide"
                        v-for="cruise in content.cruises"
                        :key="cruise.cruise_id"
                        type="cruiser"
                        :duration="cruise.days"
                        :transport-included="cruise.transport_included"
                        :image-url="cruise.image"
                        :departure-city="cruise.departure_port_name?.[LANGUAGE]"
                        :destination="cruise.destination_name?.[LANGUAGE]"
                        :itinerary="cruise.itinerary?.[LANGUAGE]"
                        :departure-date="cruise.departure_date"
                        :arrival-date="cruise.arrival_date"
                        :price="cruise.prices?.single[$store.currency]"
                        :price-full="cruise.full_price_single[$store.currency]"
                        :second-price="cruise.prices?.cabin[$store.currency]"
                        :second-price-label="$t('TEXTS.price-for-two-from')"
                        :code="cruise.cruise_code"
                        :cruiser-name="cruise.ship_name?.[LANGUAGE]"
                        :promo-name="cruise.promo_name"
                    ></showcase-item-wide>
                    <showcase-item
                        class="content-page__more-dash--cruise content-page__more-dash--thin"
                        v-for="cruise in content.cruises"
                        :key="cruise.cruise_id"
                        type="cruiser"
                        :duration="cruise.days"
                        :transport-included="cruise.transport_included"
                        :image-url="cruise.image"
                        :departure-city="cruise.departure_port_name?.[LANGUAGE]"
                        :destination="cruise.destination_name?.[LANGUAGE]"
                        :itinerary="cruise.itinerary?.[LANGUAGE]"
                        :departure-date="cruise.departure_date"
                        :arrival-date="cruise.arrival_date"
                        :price="cruise.prices?.single[$store.currency]"
                        :price-full="cruise.full_price_single[$store.currency]"
                        :second-price="cruise.prices?.cabin[$store.currency]"
                        :second-price-label="$t('TEXTS.price-for-two-from')"
                        :code="cruise.cruise_code"
                        :cruiser-name="cruise.ship_name?.[LANGUAGE]"
                        :promo-name="cruise.promo_name"
                    ></showcase-item>
                </div>
                <a
                    v-if="content.moreUrl && content.moreLabel"
                    :href="content.moreUrl"
                    class="content-page__more-btn btn btn--standalone-large"
                    :class="{
                        'btn--orange': type === 'yachtas',
                        'btn--blue': type === 'cruises'
                    }"
                >{{ content.moreLabel?.[LANGUAGE] }}</a>
            </div>
        </section>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.loading-page')"></Loading>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import { Api } from '../modules/Api';
import Loading from '../components/Loading.vue'
import Gallery from '../components/Gallery.vue'
import ShowcaseItem from '../components/ShowcaseItem.vue'
import ShowcaseItemWide from '../components/ShowcaseItemWide.vue'

import { TYPE, LANGUAGE } from '@/env'

import { Head } from '@vueuse/head'

export default {
    props: {
        contentPageName: String
    },
    components: {
        Footer,
        MainNav,
        Loading,
        Gallery,
        ShowcaseItem,
        ShowcaseItemWide,
        Head
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            type: TYPE,
            isLoading: false,
            galleryResetCounter: 0,
            content: {

            }
        }
    },
    computed: {
        oldType() {
            return this.type === 'yachtas' ? 'yacht' : 'cruiser';
        },
        contentTypeCode() {
            const types = {
                cs: {
                    yachtas: 'yc',
                    cruises: 'cc',
                },
                sk: {
                    yachtas: 'ys',
                    cruises: 'cs',
                }
            }

            return types[this.LANGUAGE][this.type];
        }
    },
    methods: {
        async loadContent() {
            this.content = await this.api.loadContent(this.contentPageName, this.contentTypeCode);
        },
    },
    async mounted() {
        this.isLoading = true;
        await this.loadContent();

        if (!this.content) {
            this.isLoading = false;
            this.$router.push(`/`);
            return false;
        }
        this.galleryResetCounter++;
        this.isLoading = false;
    },
    watch: {
        
    }
};
</script>