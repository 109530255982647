<template>
  <Head>
    <title>YACHTA'S</title>
  </Head>

  <header
    class="header-detail header-detail--yacht"
    :style="`background: url('${details.heroImage}');background-size: cover;background-position: center;`"
  >
    <div ref="sticky-header-offset">
      <Main-nav type="yacht"></Main-nav>
      <div class="breadcrumbs breadcrumbs--yacht">
        <ul class="breadcrumbs__inner">
          <li class="breadcrumbs__item">
            <router-link to="/" class="breadcrumbs__link"
              >yachtas.{{
                LANGUAGE === 'cs' ? 'cz' : LANGUAGE === 'sk' ? 'sk' : ''
              }}</router-link
            >
          </li>
          <li class="breadcrumbs__item">
            <router-link to="/vyhledat/" class="breadcrumbs__link"
              >{{ details.destinationName?.[LANGUAGE] }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="yacht-detail-page__header" ref="sticky-header-trigger">
      <div class="yacht-detail-page__header-inner">
        <div
          class="yacht-detail-page__header-content"
          :class="{ transport: includeTransport }"
        >
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--viewers
            "
          >
            <p class="yacht-detail-page__viewers-text">
              {{ $t('TEXTS.tour-viewer-part-1') }}
              <span>{{ viewCount }}</span> {{ inflect('person', viewCount) }}.
              {{ $t('TEXTS.tour-viewer-part-2') }}
              <span>{{ $t('TEXTS.tour-viewer-part-3') }}</span
              >.
            </p>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--header
            "
          >
            <h3 class="yacht-detail-page__heading">
              <span class="yacht-detail-page__subheading">
                <strong>{{ details.country?.name[LANGUAGE] }}</strong> -
                {{ details.destinationName?.[LANGUAGE] }}
              </span>
              {{ details.name?.[LANGUAGE] }}
            </h3>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--dates
              yacht-detail-page__header-part--middle
            "
          >
            <h3 class="yacht-detail-page__mini-heading">
              {{ $t('LABELS.sail-date') }}
            </h3>
            <p class="yacht-detail-page__date">
              <span class="yacht-detail-page__date-arrow">→</span>
              {{ formatDate(details.departureDate) }}
              <span>{{ details.departureName?.[LANGUAGE] }}</span>
            </p>
            <p class="yacht-detail-page__date">
              <span class="yacht-detail-page__date-arrow">←</span>
              {{ formatDate(details.arrivalDate) }}
              <span>{{ details.arrivalName?.[LANGUAGE] }}</span>
            </p>
            <div class="yacht-detail-page__yacht-name-wrapper">
              <svg class="yacht-detail-page__yacht-icon">
                <use href="/assets/imgs/icons/sprite.svg#yacht"></use>
              </svg>
              <p class="yacht-detail-page__yacht-name">
                {{ details.typeName?.[LANGUAGE] }}
              </p>
            </div>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--middle
              yacht-detail-page__header-part--middle-last
            "
          >
            <div class="yacht-detail-page__header-part__row">
              <h3 class="yacht-detail-page__mini-heading">
                {{ $t('LABELS.total-time') }}
              </h3>
              <p class="yacht-detail-page__header-text">
                {{ details.duration }} {{ daysInflected(details.days) }}
              </p>
            </div>
            <div class="yacht-detail-page__header-part__row">
              <h3 class="yacht-detail-page__mini-heading">
                {{ $t('LABELS.cruise-theme') }}
              </h3>
              <p
                :class="`yacht-detail-page__type yacht-detail-page__type--${details.theme}`"
              >
                <svg
                  :class="`yacht-detail-page__type-icon yacht-detail-page__type-icon--${details.theme}`"
                >
                  <use
                    :href="`/assets/imgs/icons/sprite.svg#${details.theme}`"
                  ></use>
                </svg>
                {{ details.themeName?.[LANGUAGE] }}
              </p>
            </div>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--prices
            "
          >
            <div
              class="yacht-detail-page__notice"
              v-if="details.label?.[LANGUAGE]"
            >
              <svg class="yacht-detail-page__notice-icon">
                <use href="/assets/imgs/icons/sprite.svg#notice"></use>
              </svg>
              <span class="yacht-detail-page__notice-text">{{
                details.label[LANGUAGE]
              }}</span>
            </div>
            <div class="yacht-detail-page__prices">
              <div
                class="yacht-detail-page__price-wrapper"
                v-if="!details.isSoldOut?.yacht"
              >
                <div
                  class="
                    yacht-detail-page__price
                    yacht-detail-page__price--discounted
                  "
                  v-if="
                    details.pricesFull?.yacht[this.$store.currency]
                      .amount_decimal > 0
                  "
                  v-html="discountPriceMarkup('yacht')"
                ></div>
                <div class="yacht-detail-page__price" v-else>
                  {{ priceText('yacht') }}
                </div>
                <button
                  @click="redirectToReservation('yacht')"
                  class="yacht-detail-page__price-btn"
                >
                  {{ $t('LABELS.book-whole-ship') }}
                </button>
                <Tooltip
                  :text="$t('TEXTS.ship-booking-hint')"
                  :triangle-in="true"
                ></Tooltip>
              </div>
              <div
                class="yacht-detail-page__price-wrapper"
                v-if="!details.isSoldOut?.cabin"
              >
                <div
                  class="
                    yacht-detail-page__price
                    yacht-detail-page__price--discounted
                  "
                  v-if="
                    details.pricesFull?.cabin[this.$store.currency]
                      .amount_decimal > 0
                  "
                  v-html="discountPriceMarkup('cabin')"
                ></div>
                <div class="yacht-detail-page__price" v-else>
                  {{ priceText('cabin') }}
                </div>
                <button
                  @click="redirectToReservation('cabin')"
                  class="yacht-detail-page__price-btn"
                >
                  {{ $t('LABELS.book-cabin') }}
                </button>
                <Tooltip
                  :text="$t('TEXTS.cabin-booking-hint')"
                  :triangle-in="true"
                ></Tooltip>
              </div>
              <div
                class="yacht-detail-page__price-wrapper"
                v-if="!details.isSoldOut?.single"
              >
                <div
                  class="
                    yacht-detail-page__price
                    yacht-detail-page__price--discounted
                  "
                  v-if="
                    details.pricesFull?.single[this.$store.currency]
                      .amount_decimal > 0
                  "
                  v-html="discountPriceMarkup('single')"
                ></div>
                <div class="yacht-detail-page__price" v-else>
                  {{ priceText('single') }}
                </div>
                <button
                  @click="redirectToReservation('single')"
                  class="yacht-detail-page__price-btn"
                >
                  {{ $t('LABELS.book-single-place') }}
                </button>
                <Tooltip
                  :text="$t('TEXTS.single-place-booking-hint')"
                  :triangle-in="true"
                ></Tooltip>
              </div>
            </div>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--note
            "
          >
            <p class="">
              {{
                upperCaseFirst(inflect('left', this.details?.freeUnits?.single))
              }}
              <strong>{{ this.details?.freeUnits?.single }}</strong>
              {{ inflect('freeMid', this.details?.freeUnits?.single) }}
              {{ inflect('place', this.details?.freeUnits?.single) }}.
            </p>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--more-info
            "
          >
            <p class="yacht-detail-page__more-info-text">
              {{ $t('TEXTS.info-about-not-included-in-price') }}
              <a href="https://yachtas.cz/kontent/plavba-na-plachetnici">{{
                $t('TEXTS.can-be-found-here')
              }}</a
              >.
            </p>
          </div>
          <div
            class="
              yacht-detail-page__header-part
              yacht-detail-page__header-part--transport
            "
            v-if="includeTransport"
          >
            <h4>{{ $t('TEXTS.transport-to-tour') }}</h4>
            <p>
              {{ $t('TEXTS.tour-with-transport') }}
            </p>
            <div class="yacht-detail-page__transports">
              <div
                class="yacht-detail-page__transport"
                v-if="this.details?.transports?.plane?.[LANGUAGE]"
              >
                <svg class="yacht-detail-page__transport__icon">
                  <use href="/assets/imgs/icons/sprite.svg#plane"></use>
                </svg>
                <p>{{ this.details?.transports?.plane?.[LANGUAGE] }}</p>
              </div>
              <div
                class="yacht-detail-page__transport"
                v-if="this.details?.transports?.car?.[LANGUAGE]"
              >
                <svg class="yacht-detail-page__transport__icon">
                  <use href="/assets/imgs/icons/sprite.svg#car-2"></use>
                </svg>
                <p>{{ this.details?.transports?.car?.[LANGUAGE] }}</p>
              </div>
            </div>
          </div>
        </div>
        <router-link
          :to="`/vyhledat/${urlCrewHash}`"
          class="
            yacht-detail-page__back-container
            yacht-detail-page__back-container--yacht
          "
        >
          <svg
            class="
              yacht-detail-page__back-icon yacht-detail-page__back-icon--yacht
            "
          >
            <use href="/assets/imgs/icons/sprite.svg#back-arrow"></use>
          </svg>
          {{ $t('LABELS.back-to-search') }}
        </router-link>
      </div>
    </div>
  </header>
  <StickyHeaderYacht :details="details" :url-crew-search="urlCrewSearch">
  </StickyHeaderYacht>
  <main class="main main--yacht yacht-detail-page">
    <main>
      <section class="yacht-detail__section">
        <div
          class="yacht-detail__section-inner yacht-detail__wysiwyg"
          v-html="details.description?.[LANGUAGE]"
        ></div>
        <!-- <div class="yacht-detail__section-inner yacht-detail__section-inner--slim">
                    <h2 class="yacht-detail__heading yacht-detail__heading--main">Ak chcete zažiť exotiku snov, vydajte sa do oslnivého tropického raja pri východnom pobreží Afriky a urobte si v zime leto.</h2>
                    <p class="yacht-detail__text">Seychely.... Nádherné ostrovy v západnej časti Indického oceána nazývané aj Galapágy Indického oceána. Navštívte s nami toto donedávna neobjavené súostrovie a poďte s nami poznávať jedinečnú faunu a flóru, živé koralové útesy a snehobiele pláže s typickými žulovými kameňmi kde určite stretnete aj voľne žijúce korytnačky obrovské.</p>
                    <h2 class="yacht-detail__heading">Cena zahrňa</h2>
                    <ul class="yacht-detail__list">
                        <li class="yacht-detail__list-item">Služby kapitána, dopravu kapitana</li>
                        <li class="yacht-detail__list-item">Prenájom katamaránu</li>
                        <li class="yacht-detail__list-item">Poistenie kaucie</li>
                    </ul>
                </div> -->
      </section>
      <section class="yacht-detail__section">
        <div class="yacht-detail__section-inner">
          <Gallery
            class="content-page__gallery"
            :images="
              details.gallery?.map((img) => {
                img.src = img.url;
                return img;
              })
            "
            :type="TYPE"
            :loop="true"
            :delay="3000"
          ></Gallery>
          <Banner
            class="yacht-detail__banner"
            :type="banner.desktop.type"
            :type-mobile="banner.mobile.type"
            :source="banner.desktop.source"
            :source-mobile="banner.mobile.source"
            :url="banner.url"
          >
          </Banner>
        </div>
      </section>
      <section class="yacht-detail__section yacht-detail__section--white">
        <div
          class="yacht-detail__section-inner yacht-detail__section-inner--long"
        >
          <h2
            class="
              yacht-detail__heading
              yacht-detail__heading--center
              yacht-detail__heading--main
            "
          >
            {{ details.sliderName?.[LANGUAGE] }}
          </h2>
          <div
            class="yacht-detail__wysiwyg yacht-detail__wysiwyg--center"
            v-html="details.sliderDescription?.[LANGUAGE]"
          ></div>
          <div class="yacht-detail__slider-container">
            <button
              class="yacht-detail__slider-btn yacht-detail__slider-btn--prev"
              :class="{
                'yacht-detail__slider-btn--disabled': swiper?.isBeginning,
              }"
              @click="prevSlide"
            >
              <svg
                class="
                  yacht-detail__slider-icon yacht-detail__slider-icon--left
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
              </svg>
            </button>
            <Swiper
              :slides-per-view="1"
              :auto-height="true"
              class="yacht-detail__slider"
              @swiper="saveSwiper"
            >
              <Swiper-slide v-for="(img, i) in details.slides" :key="i"
                ><img
                  :src="img.url"
                  class="yacht-detail__slide"
                  @load="i === 0 ? swiper.slideReset() : null"
              /></Swiper-slide>
            </Swiper>
            <button
              class="yacht-detail__slider-btn yacht-detail__slider-btn--next"
              :class="{ 'yacht-detail__slider-btn--disabled': swiper?.isEnd }"
              @click="nextSlide()"
            >
              <svg
                class="
                  yacht-detail__slider-icon yacht-detail__slider-icon--right
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
              </svg>
            </button>
          </div>
          <a
            v-if="details.slidesFile"
            :href="details.slidesFile"
            target="_blank"
            class="yacht-detail__download-btn"
            >{{ $t('LABELS.download-pdf') }}</a
          >
        </div>
      </section>
    </main>
    <!--
        ZDE BYLO: Další platby na plachetnici

        <footer class="other-offers other-offers--yacht" v-if="recommendedYachts?.length > 0">
            <div class="other-offers__inner">
                <h2 class="other-offers__heading other-offers__heading--yacht">{{ $t('LABELS.other-cruises-on-yacht') }}</h2>
                <showcase-item-wide
                    class="other-offers__item other-offers__item--wide"
                    v-for="yacht in recommendedYachts"
                    :key="yacht.code"
                    type="yacht"
                    :duration="yacht.duration"
                    :transport-included="yacht.transportIncluded"
                    :image-url="yacht.image"
                    :departure-city="yacht.sea[LANGUAGE]"
                    :destination="yacht.destinationName[LANGUAGE]"
                    :departure-date="yacht.departureDate"
                    :arrival-date="yacht.arrivalDate"
                    :price="yacht.prices.single[$store.currency]"
                    :price-full="yacht.pricesFull.single[$store.currency]"
                    :code="yacht.code"
                    :yacht-name="yacht.yachtName[LANGUAGE]"
                    :yacht-type="yacht.typeName[LANGUAGE]"
                    :theme="yacht.theme"
                    :theme-name="yacht.themeName[LANGUAGE]"
                    :default-price-label="$t('LABELS.price-first-person')"
                    :force-html-link="true"
                    :url-suffix="urlCrewSearch.slice(1)"
                    :notice-text="yacht.label[LANGUAGE]"
                ></showcase-item-wide>
                <showcase-item
                    class="other-offers__item"
                    v-for="yacht in recommendedYachts"
                    :key="yacht.code"
                    type="yacht"
                    :duration="yacht.duration"
                    :transport-included="yacht.transportIncluded"
                    :image-url="yacht.image"
                    :departure-city="yacht.sea[LANGUAGE]"
                    :destination="yacht.destinationName[LANGUAGE]"
                    :departure-date="yacht.departureDate"
                    :arrival-date="yacht.arrivalDate"
                    :price="yacht.prices.single[$store.currency]"
                    :price-full="yacht.pricesFull.single[$store.currency]"
                    :code="yacht.code"
                    :yacht-name="yacht.yachtName[LANGUAGE]"
                    :yacht-type="yacht.typeName[LANGUAGE]"
                    :theme="yacht.theme"
                    :theme-name="yacht.themeName[LANGUAGE]"
                    :default-price-label="$t('LABELS.price-first-person')"
                    :force-html-link="true"
                    :url-suffix="urlCrewSearch.slice(1)"
                    :notice-text="yacht.label[LANGUAGE]"
                ></showcase-item>
            </div>
        </footer>
        -->
  </main>
  <Footer type="yachtas"></Footer>
  <Loading
    :is-loading="isLoading"
    :label="$t('STATUSES.loading-cruise-info')"
  ></Loading>
</template>
<script>
import {
  Swiper,
  SwiperSlide,
} from '../../../node_modules/swiper/vue/swiper-vue';

import { upperCaseFirst as upperCaseFirstUtil } from 'upper-case-first';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import Tooltip from '../../components/Tooltip';
import { Api } from '../../modules/Api';
import { Dictionary } from '../../modules/Dictionary';
import formatDateUtil from '../../utilities/formatDate';
import formatPriceUtil from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';
import StickyHeaderYacht from '../../components/StickyHeaderYacht.vue';
import Gallery from '../../components/Gallery.vue';

import moment from 'moment';
import * as _ from 'lodash';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';
import { yachtasStore } from '@/utilities/store/yachtas';

const dict = new Dictionary();

export default {
  props: ['cruise_code'],
  components: {
    Footer,
    MainNav,
    Tooltip,
    Swiper,
    SwiperSlide,
    Loading,
    Banner,
    StickyHeaderYacht,
    Head,
    Gallery,
  },
  data() {
    return {
      api: new Api(this.$t),
      LANGUAGE: LANGUAGE,
      TYPE: TYPE,
      details: {
        isSoldOut: {},
      },
      recommendedYachts: [],
      showStickyHeader: false,
      gallery: {
        show: false,
        images: [],
        imageIndex: 0,
      },
      swiper: null,
      isLoading: false,
      includeTransport: false,
      banner: { url: '', mobile: {}, desktop: {} },
    };
  },
  methods: {
    upperCaseFirst(word) {
      return upperCaseFirstUtil(word);
    },
    saveSwiper(swiper) {
      this.swiper = swiper;
    },
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
    inflect(word, num) {
      return dict.getWord(word, num, LANGUAGE);
    },
    daysInflected(days_num) {
      return dict.getWord('days', days_num, LANGUAGE);
    },
    formatDate(date) {
      return formatDateUtil(date);
    },
    formatPrice(price) {
      return formatPriceUtil(price);
    },
    showGallery(index) {
      this.gallery.imageIndex = index;
      this.gallery.show = true;
    },
    setGalleryImages() {
      this.gallery.images = this.details.gallery.map((img) => img.url);
    },
    priceText(type) {
      if (this.details.prices && this.details.freeUnits) {
        if (this.details.isSoldOut?.[type]) {
          return this.$t('LABELS.soldout');
        }
        return `${this.formatPrice(
          this.details.prices[type][this.$store.currency].amount_decimal
        )} ${this.details.prices[type][this.$store.currency].currency}`;
      }
      return '';
    },
    discountPriceMarkup(type) {
      return `
                <span class='yacht-detail-page__price-discounted'>${this.formatPriceText(
                  this.details?.prices[type][this.$store.currency]
                )}</span>
                <span class='yacht-detail-page__price-original'>${this.formatPriceText(
                  this.details?.pricesFull[type][this.$store.currency]
                )}</span>
            `;
    },
    formatPriceText(price) {
      return `${this.formatPrice(price.amount_decimal)} ${price.currency}`;
    },
    redirectToReservation(type) {
      if (this.details.isSoldOut?.[type]) {
        return false;
      }

      const urls = {
        single: `/rezervace/${this.details.code}/rezervace-mist/${this.urlCrewSearch}`,
        cabin: `/rezervace/${this.details.code}/rezervace-kajut/${this.urlCrewSearch}`,
        yacht: `/rezervace/${this.details.code}/rezervace-lodi/${this.urlCrewSearch}`,
      };

      this.$router.push(urls[type]);
    },
    setSoldOut() {
      const types = ['single', 'cabin', 'yacht'];

      types.forEach((type) => {
        this.details.isSoldOut[type] =
          !this.details.prices ||
          !this.details.freeUnits ||
          this.details.prices[type][this.$store.currency].amount_decimal ===
            0 ||
          this.details.freeUnits[type] === 0;
      });
    },
    async handleRecommendedYachts() {
      const currentYachtDate = new Date(this.details.departureDate);
      currentYachtDate.setDate(1);
      let dateString = `${currentYachtDate.getFullYear()}-${
        currentYachtDate.getMonth() + 1
      }-${currentYachtDate.getDate()}`;
      dateString = dateString
        .split('-')
        .map((month, i) => (i === 1 && month < 10 ? '0' + month : month))
        .join('-');

      this.recommendedYachts = await this.api.getRecommendedYachts(
        this.details.id,
        dateString
      );
    },
    removeWysiwygStyles() {
      // const regex = / style=".*?"/gm;
      // this.details.description[LANGUAGE] = this.details.description[LANGUAGE].replaceAll(regex, '');
      // this.details.sliderDescription[LANGUAGE] = this.details.sliderDescription[LANGUAGE].replaceAll(regex, '');
    },
    async loadBanner() {
      this.banner =
        (await this.api.getBanner(TYPE, LANGUAGE, 'yacht')) || this.banner;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.loadBanner();

    this.details = {
      ...this.details,
      ...(await this.api.getYachtDetail(this.cruise_code)),
    };

    if (
      this.details.transports?.car?.[LANGUAGE] ||
      this.details.transports?.plane?.[LANGUAGE]
    ) {
      this.includeTransport = true;
    }

    if (!this.details?.code) {
      this.$router.push(`/rezervace/${this.cruise_code}/nenalezeno/`);
    }

    this.setSoldOut();
    this.removeWysiwygStyles();
    await this.handleRecommendedYachts();
    this.setGalleryImages();

    this.swiper.slideReset();
    this.isLoading = false;
  },
  computed: {
    urlCrewSearch() {
      const query = this.$route.query;
      return `?adultsNum=${query.adultsNum}`;
    },
    urlCrewHash() {
      const query = this.$route.query;
      return `#adultsNum=${query.adultsNum}`;
    },
    viewCount() {
      const viewCounted = yachtasStore.viewCounted.get(this.cruise_code);
      const isViewCountedValid =
        _.has(viewCounted, 'value') && _.has(viewCounted, 'createdAt');

      const getNewViewCount = () => {
        const _viewCount = _.random(1, 6);
        yachtasStore.viewCounted.set(this.cruise_code, _viewCount);
        return _viewCount;
      };

      if (viewCounted && isViewCountedValid) {
        const date = moment(new Date());
        const diffInSecs = date.diff(
          new Date(viewCounted.createdAt),
          'seconds'
        );

        if (diffInSecs > 60) return getNewViewCount();
        else return viewCounted.value;
      }

      return getNewViewCount();
    },
  },
  watch: {
    async $route(newRoute) {
      this.details = await this.api.getYachtDetail(newRoute.params.cruise_code);

      if (!this.details && this.$route.path.startsWith('/detail/')) {
        this.$router.push(`/rezervace/${this.cruise_code}/nenalezeno/`);
      }
    },
  },
};
</script>
