<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>
    <header class='header-detail'>
        <Main-nav type='yacht'></Main-nav>
    </header>
    <main class='main reservation'>
        <div class='reservation__inner'>
            <Reservation-nav :currentLevel='2' type='yacht' :reservation-type='type'
                             :url-query='`?type=${type}&amount=${amount}`'></Reservation-nav>
            <div class='reservation__content reservation__content--summary'>
                <h2 class='reservation__heading reservation__heading--yacht'>{{ $t('LABELS.order-summary') }}</h2>
                <div class='yacht-reservation'>
                    <ReservationSideYacht :yacht-details='yachtDetails' :price='overallPrice'></ReservationSideYacht>
                    <div
                        class='yacht-reservation__part yacht-reservation__part--overview yacht-reservation__part--summary'>
                        <div class='yacht-reservation__summary'>
                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--heading
                                yacht-reservation__summary-item--first
                            '>{{ $t('LABELS.delivery-mark') }}
                            </div>
                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--heading
                            '>
                                {{ $t('LABELS.in-total') }}
                            </div>

                            <div class='yacht-reservation__summary-hr' />

                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--first
                            '>
                                {{ $t('LABELS.cruise-on-yacht') }}, {{ yachtDetails.country?.name[LANGUAGE] }}
                                {{ formatDate(yachtDetails.departureDate) }} - {{ formatDate(yachtDetails.arrivalDate)
                                }}, {{ amount }}x {{ types[type]?.[LANGUAGE] }}
                            </div>

                            <div class='yacht-reservation__summary-item'>
                                {{ formatPrice(overallPrice.amount_decimal) }} {{ overallPrice.currency }}
                            </div>

                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--heading
                                yacht-reservation__summary-item--first
                                yacht-reservation__summary-item--mt
                            '>
                                {{ $t('TEXTS.list-of-passengers') }}
                            </div>
                            <div />

                            <div class='yacht-reservation__summary-hr' />

                            <div>
                                <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--first
                            ' v-for='passenger of passengers' :key='passenger'>
                                    <span
                                        class='yacht-reservation__summary-item--heading'>
                                        {{ passenger.firstName }} {{ passenger.lastName }}
                                    </span>
                                    <span v-if='passenger.type === "responsible"'>
                                        ( {{ $t('LABELS.responsible-person') }} )
                                    </span>
                                    <span>
                                        - {{ $t('TEXTS.non-binding-request') }}
                                    </span>
                                    <span v-if='passenger.transport.plane || passenger.transport.car'>
                                        {{ formatPassengerTransports(passenger.transport) }}
                                    </span>
                                </div>
                            </div>

                            <div class='yacht-reservation__summary-hr' />

                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--highlighted
                                yacht-reservation__summary-item--first
                            '>{{ $t('LABELS.total-to-pay').toUpperCase() }}
                            </div>
                            <div class='
                                yacht-reservation__summary-item
                                yacht-reservation__summary-item--highlighted
                            '>
                                {{ formatPrice(overallPrice.amount_decimal) }} {{ overallPrice.currency }}
                            </div>
                        </div>
                    </div>
                    <div class='yacht-reservation__part yacht-reservation__part--submit'>
                        <router-link :to='`/rezervace/${cruise_code}/platba/?type=${type}&amount=${amount}`'
                                     class='btn btn--orange btn-full-width yacht-reservation__submit-btn'>
                            {{ $t('LABELS.to-payment-and-reservation') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer type='yachtas'></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-reservation-summary')"></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationSideYacht from '../../components/ReservationSideYacht';
import ReservationNav from '../../components/ReservationNav.vue';
import formatPriceUtil from '../../utilities/formatPrice';
import formatDateUtil from '../../utilities/formatDate';
import { Api } from '../../modules/Api';
import { yachtasStore } from '@/utilities/store/yachtas';
import Loading from '../../components/Loading.vue';
import * as _ from 'lodash';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

export default {
    props: ['cruise_code'],
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            bookedUnits: 0,
            passengers: [],
            yachtDetails: {},
            type: this.$route.query.type,
            amount: this.$route.query.amount,
            types: {
                single: {
                    cs: this.$t('LABELS.bed'),
                    sk: this.$t('LABELS.bed')
                },
                cabin: {
                    cs: this.$t('LABELS.cabin'),
                    sk: this.$t('LABELS.cabin')
                },
                yacht: {
                    cs: this.$t('LABELS.ship'),
                    sk: this.$t('LABELS.ship')
                }
            },
            isLoading: false
        };
    },
    methods: {
        async loadYachtDetail() {
            this.yachtDetails = await this.api.getYachtDetail(this.cruise_code);

            if (!this.yachtDetails) {
                this.$router.push('/');
                return false;
            }
        },
        initPassengers() {
            this.passengers = yachtasStore.passengers.get(this.cruise_code);
        },
        updateBookedUnits(num) {
            this.bookedUnits = num;
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        formatDate(date) {
            return formatDateUtil(date);
        },
        formatPassengerTransports(transport) {
            const detailsTransports = this.yachtDetails.transports;

            const car = transport.car && detailsTransports.car?.[this.LANGUAGE]
            const plane = transport.plane && detailsTransports.plane?.[this.LANGUAGE]

            const transports = [car, plane].filter(Boolean).join(', ');

            return `- ${transports}`;

        },
        validateReservation() {
            const allowedTypes = ['single', 'cabin', 'yacht'];

            const validPassengers = _.size(this.passengers) > 0;

            if (!allowedTypes.includes(this.type)) {
                this.$router.push(`/detail/${this.cruise_code}/`);
                return false;
            }

            if (this.amount > this.yachtDetails.freeUnits[this.type] || !validPassengers) {
                const firstStepPath = {
                    single: 'rezervace-mist',
                    cabin: 'rezervace-kajut',
                    yacht: 'rezervace-lodi'
                };

                this.$router.push(`/rezervace/${this.cruise_code}/${firstStepPath[this.type]}/?type=${this.type}&amount=${this.amount}`);
            }
        }
    },
    computed: {
        overallPrice() {
            const price = { ...(this.yachtDetails.prices ? this.yachtDetails.prices[this.type]?.[this.$store.currency] : {}) };

            price.amount_decimal *= this.amount;
            price.amount_int *= this.amount;

            return price;
        }
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSideYacht,
        Loading,
        Head
    },
    async mounted() {
        this.isLoading = true;
        await this.loadYachtDetail();
        this.isLoading = false;
        this.initPassengers();
        this.validateReservation();
    }
};
</script>