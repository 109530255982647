<template>
    <div class="map-contact">
        <a href="https://www.google.cz/maps/place/Yachtas+coffee/@48.4379197,17.0130281,16.25z/data=!4m13!1m7!3m6!1s0x476cc214416b0f35:0x119be726033f6a13!2sMalé+námestie+5000%2F25,+901+01+Malacky,+Slovensko!3b1!8m2!3d48.4384641!4d17.0169688!3m4!1s0x476cc3a52780ad85:0xa6bf65d220546712!8m2!3d48.4383662!4d17.0173614" target="_blank" class="map-contact__link">
            <img src="/assets/imgs/content/map.jpg" class="map-contact__map-img">
        </a>
        <div class="map-contact__contact">
            <div class="map-contact__contact-section">
                <h3 class="map-contact__heading">{{ $t('LABELS.address') }}</h3>
                <p class="map-contact__text">Malé námestie 5000/15, Malacky, Slovakia</p>
            </div>
            <div class="map-contact__contact-section">
                <h3 class="map-contact__heading">{{ $t('LABELS.phone-number') }}</h3>
                <p class="map-contact__text"><a class="map-contact__link" :href="`tel:${$globalContact.cruises[LANGUAGE].phone}`">{{ $globalContact.cruises[LANGUAGE].phone }}</a></p>
            </div>
            <div class="map-contact__contact-section">
                <h3 class="map-contact__heading">{{ $t('LABELS.news') }}</h3>
                <div class="map-contact__icons">
                    <a :href="$globalContact[TYPE][LANGUAGE].facebook" target="_blank" class="map-contact__link">
                        <svg class="map-contact__icon"><use href="/assets/imgs/icons/sprite.svg#facebook"></use></svg>
                    </a>
                    <a :href="$globalContact[TYPE][LANGUAGE].instagram" target="_blank" class="map-contact__link">
                        <svg class="map-contact__icon"><use href="/assets/imgs/icons/sprite.svg#instagram"></use></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { LANGUAGE, TYPE } from '@/env'

export default {
    data() {
        return {
            LANGUAGE,
            TYPE,
        }
    }
}
</script>